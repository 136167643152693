<div class="widget-template">
    <div class="panel-left">
        <div class="group-items">
            <div class="title-text ">{{COMMON_TEXT.WIDGET_TEMPLATE_TITILE}}</div>
            <div class="frequently-used">
                <label class="lbl-frequently">{{(frequentlyUseds && frequentlyUseds.length) ?COMMON_TEXT.FREQUENTLYUSED : ''}}</label>
                <p-skeleton *ngIf="showSkeletonFrequentlyUseds && !frequentlyUseds.length" width="80px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                <div class="group-btn-used mt-10">
                    <div class="d-flex gap-10" *ngIf="showSkeletonFrequentlyUseds && !frequentlyUseds.length">
                        <p-skeleton width="50px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton width="80px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton width="50px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton width="80px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton width="50px" height="28px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                    <div class="frequently-item" *ngFor="let item of frequentlyUseds; let i = index">
                        <button class="btn-used" #frequentlyTooltips
                            [tooltipDisabled]="!isFrequentlyTextOver[i]"
                            [pTooltip]="frequentlyTooltipText[i]"
                            tooltipPosition = "top"
                            (mouseenter)="checkTextOverflow(0)"
                            (click)="onChangeTagItem(item)">{{item}}</button>
                    </div>
                </div>  
            </div>
            <div class="input-group mt-10">
                <div class="p-inputgroup input-search" (click)="clickInputSearch($event)">
                    <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                    <pivot-textbox class="input-text" [inputParams]="inputDesParams" 
                        (onInput)="onInputSearch($event)" 
                        (onKeyUp)="onKeyupInputData($event)"
                        [inputValue]="searchText"></pivot-textbox>
                </div>
                <p-overlayPanel #op [styleClass]="'custom-overlay'">
                    <ng-template pTemplate>
                        <p-listbox [options]="allWidgets" 
                            optionLabel="name"
                            [emptyMessage]="COMMON_TEXT.NO_DATA"
                            (onChange)="onSelectedWidgetFilter($event)">
                            <ng-template let-option pTemplate="item">
                                <div class="listbox-item-img" [ngClass]="option.src"></div>
                                <div class="item-name">{{option.name}}</div>
                            </ng-template>
                        </p-listbox>
                    </ng-template>
                </p-overlayPanel>
                <button class="btn-search" (click)="findListWidgetBySearchText()">{{BUTTON.SEARCH_WIDGET}}</button>
            </div>
            <div [ngClass]="searchList.length == 0 ?'itag-error' : ''">
                <div *ngIf="!searchList.length && showSkeletonSearchList" class="mt-15 d-flex gap-10">
                    <p-skeleton width="50px" height="31px" styleClass="brg-skeleton"></p-skeleton>
                </div>
                <div class="btn-write">
                    <div class="button-tag" *ngFor="let item of searchList; let i = index">
                        <div class="button-tag-lbl" #searchListTooltips  
                            [tooltipDisabled]="!isSearchListTextOver[i]"  
                            [pTooltip]="searchListTooltipText[i]"  tooltipPosition = "top"
                            (mouseenter)="checkTextOverflow(1)"
                            (click)="removeSearchItem(item)">{{item}}
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="showSkeletonDashboardNews" class="mt-25">
                <p-skeleton width="80px" height="30px" styleClass="brg-skeleton"></p-skeleton>
                <div class="mt-20">
                    <div class="mt-15 d-flex f-col gap-10">
                        <div class="w-100">
                            <p-skeleton height="28px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                        <div class="w-100">
                            <p-skeleton height="28px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                        <div class="w-100">
                            <p-skeleton height="28px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                        <div class="w-100">
                            <p-skeleton height="28px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                    </div>
                </div>
            </div>
            <div class="frequently-used lastest-grp" *ngIf="!showSkeletonDashboardNews && dashboardNews.length == 4">
                <div class="title">
                    <label class="lbl-title">{{COMMON_TEXT.LASTESTRELEASE}}</label>
                    <label class="lbl-title lbl-small" *ngIf="dashboards.length > 4" (click)="onShowDialogDashboardSelect()">{{COMMON_TEXT.SHOW_MORE}}</label>
                </div>
                <div class="frequently-dashboard">
                    <div class="dashboard-new-grp" *ngFor="let item of dashboardNews; let i = index">
                        <label class="dashboard-new" [ngStyle]="{'border' : !item.isNew ? 'none' : ''}">{{item.isNew ? 'New' : ''}}</label>
                        <button class="btn-dashboard"  #dashboardNewTooltips [tooltipDisabled]="!isDashboardNewTextOver[i]" [pTooltip]="dashboardNewTooltipText[i]" 
                            tooltipPosition = "top"
                            (mouseenter)="checkTextOverflow(2)"
                            (click)="onChangeDashboardItem(item.dashboardcd, false)">{{(item.foldername) ?? ""}}＞{{item.dashboardname}}
                    </button>
                    </div>
                </div>
            </div>

            <div *ngIf="!isLoadWidgetHistory" class="mt-25">
                <p-skeleton width="80px" height="30px" styleClass="brg-skeleton"></p-skeleton>
                <div class="mt-20 d-flex justify-content-center gap-10">
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                </div>
                <div class="mt-15 d-flex justify-content-center gap-10">
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                    <div class="d-flex justify-content-center flex-direction-column align-items-center">
                        <p-skeleton shape="circle" size="35px" styleClass="brg-skeleton"></p-skeleton>
                        <p-skeleton class="mt-3" width="110px" height="20px" styleClass="brg-skeleton"></p-skeleton>
                    </div>
                </div>
            </div>
            <div class="group-history-chart" *ngIf="widgetHistorys && widgetHistorys.length > 0">
                <label class="label-search">{{COMMON_TEXT.RECENT_SEARCHES}}</label>
                <div class="group-chart-items">
                    <div class="chart-items" (mouseenter)="checkTextOverflow(3)" 
                        [tooltipDisabled]="!isHistoryTextOver[i]" [pTooltip]="historyTooltipText[i]"
                        [tooltipPosition]="'top'"  *ngFor="let widget of widgetHistorys?.slice(0,6);  let i = index">
                        <div class="chart-item" (click)="getWidgetData(widget.widgetcd)">
                            <div class="icon-setting">
                                <div class="history-icon">
                                    <div class="chart-icon" [ngClass]="getChartIcon(widget)"></div>
                                </div>
                            </div>
                            <p class="chart-label" #historyTooltips>{{widget.widgetname}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
    <div class="panel-right">
        <div class="header">
            <div class="tile-text ">{{COMMON_TEXT.WIDGET_TEMPLATE_SEARCH}}</div>
            <div class="pagination-wrapper">
                <ng-content select="[action-buttons]">
                </ng-content>
                <pivot-pagination [totalRecord]="this.widgets.length == 0? 1 : widgetCds.length" 
                    [currentPage]="currentPage" [itemsPerPage]="pageSize" 
                    [rangePageSizes]="rangePageSizes" (paginateData)="paginateData($event)">
                </pivot-pagination>
              </div>
        </div>
        <div class="gridster-contains">

			<gridster [options]="options" #grister>
                <div *ngIf="showSkeletonWidget">
                    <div class="mt-10 d-flex gap-10" >
                        <div class="w-50">
                            <p-skeleton height="365px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="365px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                    </div>
                    <div class="mt-10 d-flex gap-10" >
                        <div class="w-50">
                            <p-skeleton height="365px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                        <div class="w-50">
                            <p-skeleton height="365px" styleClass="brg-skeleton"></p-skeleton>
                        </div>
                    </div>
                </div>
				<ng-container *ngIf="!showSkeletonWidget">
					<gridster-item [ngClass]="widgets.length == 1? 'default-width' : ''" [item]="widget" *ngFor="let widget of visibleWidgets" (itemResize)="widgetResize($event)">
						<div class="gridster-item-body">
							<div class="gridster-item-content"[id]="widget.widgetcd">
								<div class="gridster-item-content--header" [id]="widget.datasourceCd">
                                    <div class="widget-content-label">
                                        <div style="margin: 10px 15px;" *ngIf="!widget.widgetName">
                                            <p-skeleton  width="120px" height="26px" borderRadius="20px" styleClass="brg-skeleton"></p-skeleton>
                                        </div>
                                        <label class="widget-title" [ngClass]="widget.isNew ? 'max-width-title' : ''" *ngIf="widget.widgetName">{{ widget.widgetName }}</label>
                                        <label *ngIf="widget.isNew" class="widget-title widget-new">New</label>
                                    </div>
                                    <div class="widget-icon">
                                        <div class="arrow-img" (click)="openWidgetDetail(widget)"></div>
                                    </div>
								</div>
								<ng-container>
									<pivot-chart
										[widgetId]="widget.widgetcd || ''" 
										[widgetdetails]="widget.widgetdetails"
										[sortParams]="widget.sortArr || []" 
										[widgetResult]="widget.widgetResult"  
										[isLoadWidgetResultComplete]="widget.isLoadWidgetResultComplete"
										[type]="widget.type" 
										[width]="widget.width" 
										[height]="widget.height" 
										[officeList]="[]" 
										[widgetConfig]="widget.widgetConfig"
										[isHome]="true"
									>
									</pivot-chart>
								</ng-container>
								<div class="gridster-item-content--description" [ngClass]="{ 'mobile':  deviceType == DeviceType.MOBILE || deviceType == DeviceType.TABLET}" *ngIf="widget.isHaveSize === false">
									<label class="lbl-date">【{{widget.startdate}}～{{widget.enddate}}】</label>
									<label class="lbl-description" [pTooltip]="getWidgetDescription(widget.widgetcd)">{{ getWidgetDescription(widget.widgetcd) }}</label>
								</div>
							</div>
						</div>
					</gridster-item>
				</ng-container>
			</gridster>
        </div>
    </div>
</div>
<pivot-dialog-select-dashboard *ngIf="isDisplayDashboardDlg" 
    [dashboardTreeData2Level]="dashboardTreeData" 
    [nodeSelected]="dashboardNodeSelected"
    (onSubmitData)="onSubmitDashboardSelect($event)">
</pivot-dialog-select-dashboard>