import { Injectable } from '@angular/core';
import { LazyTreeNode } from 'src/app/models/common-model';

@Injectable()
export class LazyTreeViewService {
    public nodeSelecteds: LazyTreeNode[] = [];
    public dragImage: any = null;

    resetNodeSelected() {
        this.nodeSelecteds = [];
        this.dragImage = null;
    }
}
