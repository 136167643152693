import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import Utils from 'src/app/util/utils';
import { COMMON_TEXT } from '../../../../const/text-common';
import { LazyTreeNode, TreeNode } from '../../../../models/common-model';
import { LazyTreeViewService } from '../lazy-tree-view.service';

@Component({
  selector: 'pivot-lazy-tree-node',
  templateUrl: './lazy-tree-node.component.html',
  styleUrls: ['./lazy-tree-node.component.scss'],
})
export class LazyTreeNodeComponent implements OnInit, OnChanges {

  @Input() node: LazyTreeNode = new LazyTreeNode();
  @Input() nodeTree: LazyTreeNode[] = [];
  @Input() nodeClicked: LazyTreeNode;
  @Input() dragScope: string;

  @Output() nodeClick = new EventEmitter<LazyTreeNode>();
  @Output() leafNodeClick = new EventEmitter<LazyTreeNode[]>();

  showTootip: boolean = false;
  COMMON_TEXT = COMMON_TEXT;
  treeNodeStepSrc: string = "";
  expandIcon: string = '';
  collapseIcon: string = '';
  MAX_LENGTH_TOOLTIP = 100;

  constructor(private treeViewService: LazyTreeViewService) { 
    const bodyElement = document.getElementsByTagName("body")[0];
    const isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    this.treeNodeStepSrc = isDarkMode ? '../../../../../assets/icons/tree-node-step-white.svg' : '../../../../../assets/icons/tree-node-step.svg';
    if (isDarkMode) {
      this.expandIcon = '../../../assets/icons/folder-opened-white.svg';
      this.collapseIcon = '../../../assets/icons/folder-white.svg';
    } else {
      this.expandIcon = '../../../assets/icons/folder-opened.svg';
      this.collapseIcon = '../../../assets/icons/folder.svg';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.node.tooltip && this.node.tooltip?.length > this.MAX_LENGTH_TOOLTIP) {
      this.node.tooltip = this.node.tooltip.substring(0, this.MAX_LENGTH_TOOLTIP) + '\r\n...';
    }
  }

  ngOnInit(): void {
 
  }

  dragOver(event:any){
    document.body.classList.add('dragging');
  }

  dragEnd(event:any){
    document.body.classList.remove('dragging');
  }

  handleNodeClick(event: any) {
    if (!this.node.isLastNode) {
      this.node.expanded = !this.node.expanded;
      if (this.node.node) {
        this.node.node.expanded = this.node.expanded;
      }
      this.nodeClick.emit(this.node);
      this.treeViewService.resetNodeSelected();
      return;
    }
    if (this.node.draggable) {
      let nodeSelecteds = [...this.treeViewService.nodeSelecteds];
      if (event.shiftKey) { // Hỗ trợ chọn nhiều node khi nhấn Shift
        let lastSelectedNode = nodeSelecteds[nodeSelecteds.length - 1];
        if(!lastSelectedNode) {
          lastSelectedNode = this.node;
        }
        const lastIndex = this.nodeTree.findIndex(
          (node) => node.id === lastSelectedNode.id
        );
        const currentIndex = this.nodeTree.findIndex(
          (node) => node.id === this.node.id
        );

        // Chọn tất cả các node giữa 2 node đã chọn
        const range = this.nodeTree.slice(
          Math.min(lastIndex, currentIndex),
          Math.max(lastIndex, currentIndex) + 1
        );

        // Thêm tất cả các node vào danh sách đã chọn
        range.forEach((node) => {
          if (!nodeSelecteds.some((selectedNode) => selectedNode.id === node.id) && node.draggable && node.isLastNode) {
            nodeSelecteds.push(node);
          }
        });
      }
      else {
        const index = nodeSelecteds.findIndex(
          (selectedNode) => selectedNode.id === this.node.id
        );
      
        if (index > -1) {
          // Node đã tồn tại, xóa nó khỏi danh sách
          nodeSelecteds.splice(index, 1);
        } else {
          // Node chưa tồn tại, thêm nó vào danh sách
          nodeSelecteds.push(this.node);
        }
      }

      this.treeViewService.nodeSelecteds = nodeSelecteds; // Cập nhật lại danh sách node đã chọn;

      this.createImageDrag();
      this.leafNodeClick.emit(nodeSelecteds);
    } else {
      this.leafNodeClick.emit([this.node]);
    }
  }

  dragStart(event: any) {
    const nodeSelecteds = this.treeViewService.nodeSelecteds || [];
    const isParentDraggable = this.node.draggable && !this.node.isLastNode;
    if(isParentDraggable || !nodeSelecteds.length) {
      //Single select
      this.treeViewService.nodeSelecteds = [this.node];
      this.createImageDrag();
      this.leafNodeClick.emit([this.node]);
      event.dataTransfer.setData('lazy-tree-node', JSON.stringify([this.node]));
      return;
    }

    //Multi select
    const isDropItemNotSelected = nodeSelecteds.findIndex(x => x.id == this.node.id) == -1;
    if(isDropItemNotSelected) {
      nodeSelecteds.push(this.node);
      this.treeViewService.nodeSelecteds = nodeSelecteds;
      this.createImageDrag();
      this.leafNodeClick.emit(nodeSelecteds);
    }
    
    nodeSelecteds.forEach(element => {
      element.parent = new LazyTreeNode();
    });
    event.dataTransfer.setData('lazy-tree-node', JSON.stringify(nodeSelecteds));
  }
  mouseEnterContainer(event:any){
    const target = event.target as HTMLElement;
    target.classList.remove('unhover');
    target.classList.add('hover');
  }
  mouseLeaveContainer(event:any){
    const target = event.target as HTMLElement;
    target.classList.add('unhover');
    target.classList.remove('hover');
    target.classList.remove('hoverNoAnimation');
  }

  createImageDrag() {
    let nodeSelecteds = this.treeViewService.nodeSelecteds || [];

    if (!nodeSelecteds.length) {
      this.treeViewService.dragImage = null; // Không cần tạo hình ảnh nếu không có node nào
      return;
    } else {
      const fontSize = 14;
      const marginLeft = 10;
      const paddingTop = 10;
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');
      const lineHeight = fontSize + 10;
      const canvasHeight = lineHeight * Math.min(nodeSelecteds.length, 100) + 8;
      canvas.width = 161;
      canvas.height = canvasHeight;
      ctx.font = `300 ${fontSize}px Roboto`;
      ctx.textBaseline = 'top';
      ctx.fillStyle = 'gray';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = 'white';
      ctx.textAlign = 'left';
      ctx.imageSmoothingEnabled = true;

      // Vẽ danh sách node
      nodeSelecteds.slice(0, 100).forEach((s, index) => {
        const textX = marginLeft;
        const textY = index * lineHeight + paddingTop;
        ctx.fillText(s.text, textX, textY);
      });

      // Lưu hình ảnh
      const dragImage = new Image();
      dragImage.src = canvas.toDataURL();
      this.treeViewService.dragImage = dragImage;

    }
  }

  handleDragStart(e: any) {
    const dragImage = this.treeViewService.dragImage;
    if(dragImage) {
      e.dataTransfer?.setDragImage(dragImage, 0, 0);
    }
  }
}