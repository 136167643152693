export const COMMON_TEXT = {
    YES: 'はい',
    NO: 'いいえ',
    SEARCH: '検索します...',
    SEARCH_DLG:'検索を選択してください',
    ITEM_SEARCH: '項目名検索...',
    CONSTANT_SEARCH: '既定値検索...',
    TEXT_INPUT: 'テキスト入力...',
    DS_SEARCH: 'データソース名検索...',
    NO_DATA: 'データがありません。',
    CONFIRM: '確認',
    ASTERISK: '*',
    TILDE: '～',
    USER: 'ユーザ',
    STAFF: 'ユーザ',
    ONLY_ME: '自分のみ',
    EVERYONE: '全員',
    GROUP: '公開グループ',
    ALL_SELECTED: '全て選択されている。',
    NAME: '名前',
    DESCRIPTION: '説明',
    FOLDER: 'フォルダ',
    DATASOURCE: 'データソース',
    DASHBOARD: 'ダッシュボード',
    WIDGET: 'ウィジェット',
    OFFICE: '事業所',
    CONFIRM_DELETE: '削除確認',
    CONFIRM_NOT_SAVE_DATA: '未保存データ確認',
    THIS_MONTH: '今月',
    EXTEND_COPY: '_コピー',
    TOGGLE: '絞込み',
    ITEM_SELECT: '項目選択',
    ITEM: '項目',
    FILTER_CONDITION: '絞込み条件',
    REQUIRE_ITEM: '必須項目',
    RESULT: '表示件数',
    SELECT_ALL: 'すべて選択',
    SORT_COLUMN: 'ソート列:',
    SORT_MODE: '並べ替えモード:',
    FILTER_COLUMN: 'フィルター列:',
    VALUE: '価値：',
    RESET_ALL: 'すべてリセット',
    NUMBER: 'No.',
    FAIL: '失敗',
    EMPTY_NODE: '(なし)',
    PIVOT_TITLE: 'CAREKARTE Pivot',
    PUBLIC_TO: '公開先',
    CLEAR_FILTER: 'フィルタをクリア',
    ERROR_MODAL_HEADER: 'エラー通知',
    SELECT_TITILE: 'タイトル選択',
    PUBLISH: '公開',
    UNPUBLISH: '非公開',
    SORTTABLE: 'フィルター',
    SYNC_MASTER_LIST: '同期進捗',
    TARGET_VALUE_SETTING: '目標値設定',
    WELCOME_TEXT: 'はじめに',
    CONFIRM_NO_OFFICE: 'PIVOTの利用を開始する為に同期を行ってください。（初回同期）',
    SYNC_MASTER_DATA_LINK: 'マスタ同期をする',
    MULTIFILTER: '※複数ワードでフィルタする場合、「;」で区切る',
    LIMITDATA: '設定されている列・行の項目が多い、\nまたは列・行に設定されている項目のパターンが多い為、グラフ作成をする事ができません。\n列・行に設定されている項目の設定を見直してください。',
    SEARCH_TEXT: '検索します…',
    SYNC_LOADING: '同期実行中...',
    SYNC_SUCCESS: '同期実行完了',
    USER_DEFINED_COLUMN_MANAGERMENT: 'ユーザー定義列管理',
    COLUMN_NAME: '列名',
    DESCRIPTION_DEFINED_COLUMN:'※半角スペースキーを押すと列に指定した項目が表示されます。',
    GROUP_TEXT:  'グループ化',
    BELOW: 'より小さい',
    UPPER: 'より大きい',
    TIME: '分',
    DAY: '日',
    MONTH: '月',
    YEAR: '年',
    LAST_VALUE:'未尾の値',
    FIRST_VALUE: '先頭の値',
    HOUR: '時',
    COLUMN_NAME_ALREADY_EXISTS: '列名が既存しています。',
    COMBINE_INTELLIGENT:'結合情報',
    ERROR_MESSAGE_TITLE_NOT_EXIST: '該当するタイトルが存在しません。データソースの作成が失敗しました。',
    INFORMATION: '情報',
    VALUE_EMPTY: '',
    WIDGET_TEMPLATE_TITILE: 'テンプレート',
    WIDGET_TEMPLATE_SEARCH: '検索結果',
    CREATE_WIDGE_TEMPLATE: 'テンプレート／ウィジェット作成',
    DASHBOARD_SELECTION: 'ダッシュボード選択',
    REPORT_CREATE: 'ウィジェット作成',
    SELECT_OFFICE: '集計対象事業所を選択してください。',
    OFFICE_SELECTION: '事業所選択',
    PERIOD: '期間',
    DECIDED: '未定',
    SELECT_PERIOD: 'が選択されています。',
    DASHBOARD_DESTINATION: 'ウィジェット表示先',
    DASHBOARD_SELECT: '表示先選択',
    ISSUE: '発行',
    PUBLISH_REPORT: '集計期間に使用するレポートを発行します。',
    SELECT_DASHBOARD_TO_VIEW: 'ダッシュボードを選択してください。',
    SELECT_FOLDER_TO_VIEW: 'フォルダを選択してください。',
    ORIGINAL_DATA: '原本データ',
    SUMMARY_TABLE: '集計表',
    GRAPH_SETTING: 'レポート設定',
    GRAPH_TYPE: 'グラフ種類',
    LEGEND_DISPLAY: '凡例表示',
    AXIS_DISPLAY: '軸表示',
    GRAPH_DISPLAY: 'グラフ表示',
    CUSTOMIZATION: 'カスタマイズ',
    RECENT_SEARCHES: '最近の検索',
    SELECT_ALL_OFFICE: '全事業所',
    SELECTED_OFFICE: 'つ事業所を選択しました。',
    WIDGET_INFOMATION: 'ウィジェット情報',
    WIDGET_NAME: 'ウィジェット名',
    WIDGET_DESC: '説明',
    CHOICE: '選択',
    SETTING: '追加',
    FREQUENTLYUSED: 'よく使用',
    LASTESTRELEASE: '最新公開',
    WIDGET_SETTING: '設定',
    DATASOURCE_SAVE: 'データソース保存先設定',
    FOLDER_DATASOURCE: 'データソースフォルダ選択',
    CREATE_DATASOURCE: '新規にデータソースを作成する',
    DATASOURCE_EXIST: '既に同じデータソースが存在します。',
    USE_DATASOURCE_EXIST: '既にあるデータソースを使用する',
    WIDGET_DESTINATION: 'ウィジェット保存先',
    SHOW_MORE: 'さらに表示',
    DASHBOARD_TEMPLATE_SELECTION: 'ダッシュボードテンプレート選択',
    TEMPLATE_SELECTION: 'テンプレート選択',
    EXCEEDED_MAX_COLUMNS: '列数は500件が超えています。',
    SELECTED: '選択します',
    START_MONTH: '開始月',
    END_MONTH: '終了月',
}

export const MENU_NAME = {
    HOME: "ダッシュボード",
    DATASOURCE: "データソース一覧",
    DATASOURCE_EDIT: "データソース編集",
    DATASOURCE_CREATE: "データソース作成",
    DATASOURCE_PREVIEW: "データソース表示",
    DATASOURCE_TEMPLATE: "データソース一覧(テンプレート)",
    DATASOURCE_TEMPLATE_EDIT: "データソース編集(テンプレート)",
    DATASOURCE_TEMPLATE_CREATE: "データソース作成(テンプレート)",
    DATASOURCE_TEMPLATE_PREVIEW: "データソース表示(テンプレート)",
    DASHBOARD_LIST: 'ダッシュボード一覧',
    DASHBOARD_TEMPLATE: 'ダッシュボード一覧(テンプレート)',
    SYSTEM_SETTING: 'システム設定',
    TAB_SEIKUY:'請求TAB表示',
    DASHBOARD_SETTING: 'ダッシュボード設定',
    DASHBOARD_CREATE: '新規ダッシュボード',
    WIDGET_LIST: 'ウィジェット一覧',
    WIDGET_CREATE: 'ウィジェット作成',
    WIDGET_DETAIL: 'ウィジェット編集',
    WIDGET_TEMPLATE_CREATE: 'ウィジェット作成(テンプレート)',
    ZAITAKU: '在宅復帰・在宅療養等指数データソース作成',
    DASHBOARD_WIDGET_TEMPLATE: 'テンプレート選択',
    HOMETEXT: 'ホーム',
    BASIC_INFO: 'マイアカウント',
    CK_REPORT: '在宅復帰・在宅療養等指標',
    AGGREGATION_LIBRARY: 'テンプレート',
}

export const BUTTON = {
    CREATE: '作成',
    DELETE: '削除',
    EDIT: '編集',
    YES: 'はい',
    NO: 'いいえ',
    ADD: '追加',
    RETURN: '戻る',
    SAVE: '保存',
    SAVE_AND_CLOSE: '保存して閉じる',
    NEXT: '次へ',
    SEND: '送信',
    UPDATE: '更新',
    SEARCH: '検索する',
    UPDATE_NEW_DATA: '最新データ更新',
    SELECT: '選択',
    SET: '確定',
    CANCEL: 'キャンセル',
    LOGIN: 'ログイン',
    LOGOUT: 'ログアウト',
    INVITE: '招待する',
    PREVIEW: 'プレビュー',
    OFFICE_SELECT: '事業所選択',
    DATASOURCE_SELECT: 'データソース選択',
    SELECT_FOLDER: 'フォルダ選択',
    COPY: '別名で保存',
    MY_ACCOUNT: 'マイアカウント',
    HELP: '案内',
    SYS_SETTING: 'システム設定',
    ROLE_CREATE: 'ロール追加',
    ROLE_DELETE: 'ロール削除',
    GROUP_CREATE: '新規グループ',
    FOLDER_CREATE: '新規フォルダ',
    FOLDER_SELECT: 'フォルダを選択',
    DATASOURCE_CREATE: '新規\r\nデータソース',
    DASHBOARD_CREATE: '新規\r\nダッシュボード',
    WIDGET_CREATE: '新規\r\nウィジェット',
    ADD_WIDGET: 'ウィジェット\r\n追加',
    PIVOT_INVITE: '職員をPIVOTに招待する',
    START_SYNC: '同期開始',
    CATEGORY_CREATE: 'カテゴリー作成',
    SELECT_TEMPLATE: 'テンプレート\r\n選択',
    CLOSE: '閉じる',
    GRAPH_SETTINGS: 'グラフ設定',
    REQUEST_SYNC: '更新可能',
    MANAGERMENT: '管理',
    COPY_DEFAULT_VALUE: '複写',
    DEFAULT: '既',
    SEARCH_WIDGET: '検索',
    NEW_DASHBOARD: '新規ダッシュボード',
    SETTING:'設定'
}

export const UPDATE_DATA = {
    DASHBOARD_TITLE : 'ダッシュボード',
    WIDGET_TITLE: 'ウィジェット',
    DATA_SOURCE_TITLE: 'データソース',
    CHECK_BOX_WORK:'する',
    CHECK_BOX_NOT_WORK:'しない'
}

export const LOGIN = {
    LOGIN_TITLE: 'CAREKARTE Passでログイン',
    ID_LBL: 'メールアドレス または 携帯電話番号',
    PASSWORD_LBL: 'パスワード',
    RESET_PASSWORD_LBL: 'パスワードを忘れた方はこちら',
    LOGIN_ERR_MSG: '入力した内容に誤りがあります。\nもう一度入力してください。',
    LOGIN_CONSOLE_ERR_MSG: '「@carekarte.jp」のメールアドレスでのみログインできます。\nもう一度やり直してください。',
    LOGIN_ERR_500_MSG: 'ログインの処理に失敗しました。管理者に連絡してください。',
    TEMPLATE_SETTING: 'テンプレート設定',
    ERR_USER_LOCKED: 'ERR_USER_LOCKED',
    LOGIN_APP: 'CAREKARTE Pivot',
}

export const RESET_PASSWORD = {
    RP_TITLE: 'パスワードの再設定',
    RP_INFO_LINE_1: 'パスワードの再設定を行いたいCAREKARTE Passのメールアドレス、または携帯電話番号を入力してください。',
    RP_INFO_LINE_2: 'パスワードの再設定を行わない場合は',
    RP_ERR_MSG: 'メールアドレスを入力してください。',
    SENT_MSG_TITLE: 'メッセージ送信',
    SENT_MSG_CONTENT_1: 'ご入力いただいたメールアドレス、または携帯電話番号宛にメッセージを送信しました。',
    SENT_MSG_CONTENT_2: 'メッセージに記載されたURLから、パスワードの再設定を行ってください。',
    BACK_TO_LOGIN: 'ログイン画面へ',
}

export const NOT_FOUND = {
    TITLE: '404 Page Not Found',
    SUB_TITLE: '指定されたページは存在しません',
    CONTENT: '大変申し訳ございませんが、お探しのページは、削除されたか、ファイル名が間違っている可能性があります。アドレスを確認してください。',
    BACK_BUTTON: '前のページへ戻る'
};

export const HOME = {
    SEARCH_DASHBOARD: 'ダッシュボード検索...',
    SEARCH_WIDGET: 'ウィジェット検索...',
    SEARCH_DATASOURCE: 'データソース検索...',
    NO_DATA: 'ダッシュボードがありません。\n\n作成するか、作成済みのダッシュボードに\n権限を設定するよう管理者へ依頼してください。'
}

export const BASIC_INFO = {
    ID: 'ID',
    FURIGANA: 'フリガナ',
    BELONGS: '所属',
    USER_NAME: 'ユーザーID',
    EMAIL: 'メール',
    ROLE: 'ロール',
    AVATAR_RULE_TITLE: '通知',
    AVATAR_RULE_CONTENT_1: '選択したファイルのフォーマットがサポートされない。サポートされるフォーマット：',
    AVATAR_RULE_CONTENT_2: '・ファイルの拡張子は「svg, jpg, jpeg, png」です。',
    AVATAR_RULE_CONTENT_3: '・ファイルサイズは最大3MBです。',
    UPDATE_INF_BTN: '変更する',
    PHONE_NUMBER: '携帯電話番号',
    LOGIN_INFO: "ログイン情報",
    CORP: "法人名",
    CUSTID: "顧客ID"
}

export const SYSTEM_SETTING = {
    ROLE: 'ロール',
    ROLE_NAME: 'ロール名',
    ROLE_EDIT: 'ロール編集',
    ROLE_CREATE: 'ロール作成',
    ROLE_DELETE_DECLINE: 'ロールを付与されている人がいる為、削除できません。',
    ROLE_SETTING_TAB: 'ロール設定',
    CORP_SETTING_TAB: '法人の設定',
    STAFF_INFO_TAB: '職員情報',
    RESTRICTION_SETTING: '各種設定の制限',
    ALL_CHECK_UNCHECK: '全チェック/全チェック解除',
    MONTH_SETTING: '開始月設定',
    START_MONTH: '開始月',
    INCLUDE_EDATE_DISCHARGE: '延入所者数、新規入所者の延数に退所者の退所日を含まない',
    NURSING_HOME_CARE_INDEX: '老健 在宅復帰・在宅療養等指数',
    CORP_DESIGN: '法人の設計',
    STAFF_INFO_TITLE: '職員情報設定',
    GROUP_EDIT: 'グループ編集',
    GROUP_CREATE: 'グループ作成',
    NOT_TEAM: 'グループ未定',
    GROUP_NAME: 'グループ名',
    GROUP_SELECT: 'グループ選択',
    ROLE_SELECT: 'ロール選択',
    STAFF_INFO_UPDATE: '職員情報編集',
    INVITE_DLG_TITLE: '招待確認',
    UPDATE_DIALOG_TITLE:'最新データ更新確認',
    INVITE_DLG_SUB_TITLE: '職員をPIVOTに招待する',
    INVITE_DLG_CONTENT: 'ログインしている事業所のCAREKARTE Pivotコントロールに職員を招待します。\n招待する職員のメールアドレス、もしくは携帯電話番号を入力して、「招待する」ボタンをクリックしてください。',
    INVITE_DLG_TYPE: '招待する方法',
    INVITE_DLG_MAIL: 'メールアドレス',
    INVITE_DLG_PHONE: '携帯電話番号',
    PIVOT_MASTER_SYNC: 'Pivot CKマスタ同期',
    MASTER_SYNC: 'CKマスタ同期',
    DEFAULT_VALUE: '既定値設定',
    DEFAULT_VALUE_SETTING: '条件名称',
    SEARCH_AREA:'検索エリア',
    RELATED_WIDGET_UPDATE: '関連ウィジェット更新',
    SYNC_INFO: 'PIVOTの利用を開始する為に同期を行ってください。（初回同期）\nまた、CAREKARTEで記録や帳票のタイトルを更新した時や事業所を追加した時は、PIVOTへ反映させるために同期処理を行ってください。\n事業所の数によっては同期処理が数十分かかることがございます。',
    PENDING_INVITATION_TITLE: '招待済み',
    STAFF_LIST_LABEL: 'グループ名一覧',
    CANCEL_INVITED_STAFF: '取消'
}

export const SYSTEM_SETTING_CODE = {
    MONTH_SETTING: '0001',
    INCLUDE_EDATE_DISCHARGE: '0002'
}
export const DATA_UPDATE = {
    TITLE: 'データ更新',
    ERROR_MESSAGE: '選択されているデータソースが上限の20件を超えています。20件以内にしてください。',
    NO_DATA_MESSAGE:'データがありません。',
    ERROR_UPDATE_DATA_MESSAGE:'データ読み込みに失敗したため、同期処理を中断しました'
}

export const CK_REPORT_TEXT = {
    TITLE: '在宅復帰・在宅療養等指数データソース作成',
    TITLE2: '検索します...',
    DIRECT_INPUT: '直接入力',
    AUTO_ACQUISITION_SETTING: '自動取得設定',
    DATA_ACQUISITION: 'データ取込み',
    NOTE: '※表示期間は12カ月以内でお願いします。',
    ERROR_NAME: '必須項目を入力してください。',
    ERROR_OPTION: '必須項目を指定してください。',
    ERROR_OVERLAP: 'この時間は重複しています。もう一度選択してください。',
    ERROR_EDATE_LATER_THAN_SDATE: '開始日が終了日より後の日付を指定しています。',
    DISPLAY_PERIOD: '表示期間',
    VALIDITY_PERIOD: '有効期間',
    CAPTURE_REFERNCE: '取込み基準月',
    TO: '～',
    DATA_NOTE: '在宅復帰・在宅療養等指数（各月）のデータソース',
    SQUARE: '■',
    HAS_HOSPITAL_NEARBY: '同一敷地内又は隣接する敷地内に病院又は診療所あり',

    //dialog settings
    ADD_CONDITION: '条件追加',
    DELETE_CONDITION: '条件削除',
    CONDITION_NAME: '条件名',
    COL_LIST: '在宅復帰・在宅療養等指数スキーマ',
    RECORDED_ITEMS: '記録項目',
    CONDITION: '条件',
    VALUE: '値',
    COUNTING_METHOD: 'カウント方法',
    NUMBER_OF_TIMES: '回数',
    NUMBER_OF_DAYS: '日数',
    NUMBER_OF_DAYS_OF_USE: '延べ利用日数',
    NUMBER_OF_USERS: '延べ人数',
    SEARCH_CONDITION: '条件検索',
    DELETE_RECORD: '記録項目削除'
}

export const FOLDER_DIALOG = {
    FOLDER_DLG_CREATE: 'フォルダ作成',
    FOLDER_DLG_EDIT: 'フォルダ編集',
    FOLDER_DLG_NAME: 'フォルダ名',
    FOLDER_DLG_SHARE: '共有先',
    FOLDER_DLG_ACCESS: 'アクセス',
}

export const DATASOURCE_LIST = {
    TITLE: 'データソース一覧',
    OFFICE_DLG_TITLE: '事業所選択',
    WARNING_WHEN_DELETE_DATASOURCE: '以下のウィジェットとダッシュボードが関連してます。',
    WARNING_WHEN_DELETE_WIDGET: '以下のダッシュボードが関連してます。',
    WIDGET_LIST: '【ウィジェット】',
    DASHBOARD_LIST: '【ダッシュボード】',
    SELECT_WIDGETS_TITLE: 'ウィジェット同期確認'
}

export const DASHBOARD_LIST = {
    TITLE: 'ダッシュボード一覧',
    DASHBOARD_DLG_TITLE: '新規ダッシュボード',
    DASHBOARD_DLG_CREATE: '新規 ダッシュボード',
    DASHBOARD_DLG_EDIT: '編集 ダッシュボード',
}

export const WIDGET_LIST = {
    TITLE: 'ウィジェット一覧',
}

export const DASHBOARD_SETTING = {
    TITLE_PREVIEW: 'ダッシュボード表示',
    TITLE_CREATE: 'ダッシュボード作成',
    TITLE_EDIT: 'ダッシュボード編集',
    CP_DOWNLOAD: 'ダウンロード',
    CP_NEW: '新規ダッシュボード',
    PUBLIC_SETTING: '公開設定',
    PUBLIC_TO: '公開先',
}

export const DATASOURCE_SETTING = {
    COLUMN_OFFICE_ITEM: '事業所選択・項目設定',
    COLUMN_LAYOUT: 'レイアウト',
    OFFICE_SELECT: '事業所選択',
    CLEAR_ALL: '全てクリア',
    DS_COLUMN: '列',
    DS_PERIOD: '期間設定',
    DS_DESTINATION: '保存先',
    DS_ORDERS: 'その他', 
    DS_USER_DEFINED: 'ユーザー定義',
    DS_USER_DEFINED_COLUMN: 'ユーザー定義列',
    DS_FOLDER: 'データソースフォルダ',
    DS_NAME: 'データソース名',
    DS_RECORD_ITEM: '記録項目',
    DS_TAG: '#タグ',
    DS_MASTERDATA: 'マスタ',
    DS_SEIKUY: '請求',
    MSG_MORE_THAN_100: '※ 表示可能件数を超えました。100件まで表示しています。',
    DS_COPR: '法人',
    DS_OFFICE: '事業所',
    DS_MASTER_SUBLABEL: 'マスタ系データ',
    DS_SEIKUY_SUBLABEL: '請求系データ',
    DS_ONLY_LASTEST_DATA: '最新データのみ取得',
    DS_DISPLAY_DATE: '日付表示',
    DS_AGE: '年齡',
    DS_CALCULATION_DATE: '算出日',
    DISPLAY_BLOCK_UNIT: 'ブロック・ユニット表示',
    DS_REFERENCE_DATE: '基準日',
    DS_CAN_JOINED: '結合できるデータソース',
    USER_MASTER: '利用者マスタ',
    NURSING_CARE_INSURANCE_INFORMATION: '介護保険情報',
    YOTEI: '負担限度履歴',
    RECORD: '記録',
    REPORT: '帳票',
    SCHEDULE: '予定',
    USER_MASTER_ONLY: '利用者マスタのみ',
    AGGREGATION_TYPE: '集計タイプ',
    INCLUDES_START_DATE: '開始日を含む',
    INCLUDES_DATE_DISCHARGE: '退所日を含む',
    VACANT_BED_DISPLAY: '空床期間表示',
    ADMISSION_HISTORY: '入所(院)履歴',
    SLEEPOVER_HOSPITALIZATION_HISTORY: '外泊入院履歴',
    ROOM_MOVERMENT_HISTORY: '居室移動履歴',
    HISTORY_OF_IN_HOME_SERVICE_START_OR_CANCEL:'居宅サービス開始中止履歴',
    PUBLIC_EXPENSE_INFORMATION:'公費情報',
    RECIPIENT_ID:'受給者証',
    RECIPIENT_ID_HISTORY: '受給者証情報 (総合支援)',
    USAGE_ACCRUAL_START_DATE_HISTORY: '短期利用加算起算日履歴',
    ADMISSION_HISTORY_SUPPORT: '入所 (院)履歴 (総合支援)',
    SLEEPOVER_HOSPITALIZATION_HISTORY_SUPPORT: '外泊入院履歴 (総合支援)',
    HISTORY_OF_IN_HOME_SERVICE_SUPPORT: '居宅サービス開始中止履歴 (総合支援)',
    HISTORY_OF_SHOFT_TERM_USE_ADDITION_SUPPORT:'短期利用加算起算日履歴 (総合支援)'
}

export const WIDGET_SETTING = {
    WIDGET_NAME: 'ウィジェット名',
    WIDGET_EDIT: 'ウィジェット編集',
    WIDGET_CREATE: 'ウィジェット作成',
    WIDGET_VIEW: 'ウィジェット表示',
    WIDGET_DESC: 'ウィジェット説明',
    WIDGET_CHART_TYPE: '表示形式',
    WIDGET_GROUP: 'グループ',
    WIDGET_FOOTER: '集計行',
    WIDGET_SUMMARYCOLUMN: '集計列',
    CUSTOM_TREE: 'ユーザー定義列・目標値追加',
    HEADER1: 'ユーザー定義列・目標値追加',
    DEFINED_COL_NAME: '追加列名',
    DEFINED_COL_CONDITION: '条件',
    DEFINED_TRUNCATE: '切り捨てる',
    DEFINED_ROUNDUP: '切り上げる',
    DEFINED_ROUNDOFF: '四捨五入する',
    VALID_PERIOD: '有効期間',
    CHANE_ALL_OPTION: '属性一括変更',
    VALUE: '値',
    LIST: '一覧',
    GRAPH: 'グラフ',
    AXIS_2: '第2軸',
    DISPLAY_SETTINGS: '表示設定',
    SWITCH_COLUMN_ROW: 'スイッチ列・行',
    MERGE_TABLE_CELL: '行結合'
 }


export const SYNCDATA_STATUS = {
    NOT_UPDATED: '未更新',
    UPDATING: '更新中',
    UPDATE_COMPLETE: '更新完了',
    ERROR: 'エラー'
}

export const DATASOURCE_TEMPLATE_LIST = {
    TITLE: 'データソース一覧(テンプレート)',
}

export const DASHBOARD_TEMPLATE_LIST = {
    TITLE: 'ダッシュボード一覧(テンプレート)',
    DASHBOARD_DLG_TITLE: '新規ダッシュボード(テンプレート)',
    DASHBOARD_DLG_CREATE: '新規 ダッシュボード(テンプレート)',
    DASHBOARD_DLG_EDIT: '編集 ダッシュボード(テンプレート)',
}

export const CATEGORY_DIALOG = {
    CATEGORY_DLG_CREATE: 'カテゴリー作成',
    CATEGORY_DLG_EDIT: 'カテゴリー編集',
    CATEGORY_DLG_NAME: 'カテゴリー名',
    CATEGORY_DLG_PUBLICING_SETTING: '公開設定',
    CATEGORY_DLG_VALID_PERIOD: '有効期間',
}

export const DASHBOARD_TEMPLATE_SETTING = {
    TITLE_PREVIEW: 'ダッシュボード表示(テンプレート)',
    TITLE_CREATE: 'ダッシュボード作成(テンプレート)',
    TITLE_EDIT: 'ダッシュボード編集(テンプレート)',
    TITLE_SELECT: 'テンプレート選択',
    DS_FOLDER: 'データソースフォルダ',
    CP_DOWNLOAD: 'ダウンロード',
    CP_NEW: '新規ダッシュボード',
    FILTER_PUBLIC: '公開のみ表示',
}

export const WIDGET_TEMPLATE_LIST = {
    TITLE: 'ウィジェット一覧(テンプレート)',
}

export const WIDGET_TEMPLATE_SETTING = {
    WIDGET_EDIT: 'ウィジェット編集(テンプレート)',
    WIDGET_CREATE: 'ウィジェット作成(テンプレート)',
    WIDGET_VIEW: 'ウィジェット表示(テンプレート)',
}
export const CORP_SELECTION = {
    LABEL: 'ログインする法人を選択してください。',
}

export const LOCK_ACCOUNT = {
    CONTENT: '  一定回数ログインに失敗したため、CAREKARTE Passのロックを行いました。\r\n ログイン画面または以下のボタンからパスワードの再設定を行うか \r\n 15分が経過するとロックが解除されます。',
    TITLE:'アカウントロック ',
    BUTTON: ' 再設定画面へ'
}
export const HELP_LIST = {
  TITLE: '案内',
  CCJ_SITE: 'ケアコネクトジャパンサポートサイトは',
  HERE: 'こちら',
  UNREAD: '未読',
  HIDE_POPUP: '今後、このメッセージを表示しない'
}

export const DEFAULT_VALUE_SETTING = {
  CONDITION_INVALID: '条件無効',
  TITLE_CATEGORY_TAG: 'タイトル区分タグ',
  TITLE_TAG: 'タイトルタグ',
  INVALID: '無効',
  ENABLED: '有効',
}

export const CREATE_DASHBOARD_TEMPLATE =  {
    FOLDER_VALIDATE_ERROR: 'フォルダを指定してください。',
    DASHBOARD_VALIDATE_ERROR: '表示先を指定してください。',
    OFFICE_VALIDATE_ERROR: '事業所を指定してください。',
    CUSTOM_PERIOD_ERROR: 'カスタマイズにて期間を指定する場合1年以内（365日以内）で開始日、終了日を指定してください。',
    SELECT_FOLDER_DATASOURCE: 'データソースフォルダを指定してください。',
    DATA_SOURCE_DESTINATION: 'データソース保存先',
    SELECT_DATASOURCE_DESTINATION: 'フォルダを選択してください。',
    DATASOURCE_VALIDATE_ERROR: 'データソースを指定してください。',
    SEARCH_DASHBOARD: 'ダッシュボード検索...',
    SEARCH_FOLDER: 'フォルダ検索...',
    SEARCH_DATASOURCE: 'データソース検索...',
    DATASOURCE_DISPLAY_DESTINATION: 'データソース保存先設定',
    WIDGET_SAVE_DESTINATION: 'ウィジェット保存先設定',
    WIDGET_DISPLAY_DESTINATION: 'ウィジェット表示先設定',
    

}

export const DIALOG_CONFIRM_SAVE_DATA =  {
    OFFICE: '事業所',
    PERIOD: '期間',
    FOLDER_DATASOURCE: 'データソース保存先',
    DATASOURCE_NAME: 'データソース名',
    DASHBOARD_NAME: 'ウィジェット保存先',
    WIDGET_NAME: 'ウィジェット名',
    FOLDER_WIDGET: 'ウィジェット表示先',
    DEFAULT: 'デフォルト',
    CUSTOMIZATION: 'カスタマイズ',
    SAVE_FOLDER: '保存フォルダ先',
    DISPLAY_DISTINATION: '表示先',
    CONFIGURE_INFOMATION: '下記の情報で設定します。ご確認ください。',
    MOVE_TO_DASHBOARD: 'テンプレートのままでダッシュボード作成画面へ行きます。',
    MOVE_TO_WIDGET: 'ウィジェット作成画面へ遷移してカスタマイズを行います。'
}


export const DIALOG_DASHBOARD_SERVICE = {
    FOLDER_DESTINATION: 'フォルダ先'
}