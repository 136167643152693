import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ModalParam, ModalTemplate, SearchParams } from '../../../models/common-model';
import { BUTTON, COMMON_TEXT, DATA_UPDATE, HOME, SYNCDATA_STATUS, SYSTEM_SETTING ,UPDATE_DATA } from '../../../const/text-common';
import { ButtonType,CheckAllMode,SearchType ,DataType, SyncDataStatus, SyncDataStatusBackEnd, DateFormat, ButtonIconType, DataSourceType} from '../../../enum/common-enum';
import { TableData } from '../../../models/table-model';
import { cloneDeep } from 'lodash';
import { ErrorHandleService } from '../../../services/error-handle.service';
import { FUNCTION_TITLE_TEXT } from '../../../const/error-text';
import { ProcessLoadingService } from '../../../services/loading.service';
import { Dashboard } from '../../../models/response/dashboard.ro';
import { Widget } from '../../../models/request/widget.dto';
import { AuthenticationService } from '../../../services/authentication.service';
import { DataSourceService } from '../../../services/modules/data-source.service';
import { SharedDataService } from '../../../services/share-data.service';
import { sharedDataKey} from '../../../const/const';
import { SyncLogDataModel } from '../../../models/response/sync-log-data.ro';
import { v4 as uuid } from 'uuid';
import { SyncLogDataService } from '../../../services/modules/sync-log-data.service';
import { DatasourceSyncDataModel, DatasourceSyncModel } from '../../../models/response/datasource.ro';
import { ResponseData } from '../../../models/api-model';
import { CorpMstModel } from '../../../models/response/corpMst.ro';
import { MstCommonService } from '../../../services/modules/mstcommon.service';
import { CorpMstService } from '../../../services/modules/corpmst.service';
import { LocalStorageHelper } from '../../../_helper/local-storage.helper';
import { HandleSyncDataDataService } from '../../../services/modules/handle-sync-data.service';
import { MESSAGE_TEXT } from '../../../const/message';
import * as moment from 'moment';
import {DashboardService} from 'src/app/services/modules/dashboard.service';
import { SAUCER_LOG_ACTION, SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { CONTENT_LOG } from 'src/app/config/saucer-log.config';
import { API_APP } from 'src/app/config/app.config';
import { WidgetUpdateService } from 'src/app/services/widget-update.service';

interface syncWidgetModel {
  widgetname: string,
  widgetcd: string,
  dsName: string,
  datasourceCd: string,
  dashboardname: string,
  relatedwidgetnames: string,
  staff:  string,
  date: string,
  status: any,
  process: string,
  checked: boolean,
  statusMessage: string,
  id: string
}

@Component({
  selector: 'pivot-dialog-widget-update-component',
  templateUrl: './update-widget-dialog-component.component.html',
  styleUrls: ['./update-widget-dialog-component.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogUpdateComponentComponent implements OnInit, OnChanges {
  @Input() syncWidgetLog: {screenName: string, action: string, body: string} | null = null;
  @Input() isDisplayModal: boolean = false;
  @Input() modalData: ModalTemplate = new ModalTemplate();
  @Input() listDashboard : Dashboard[] = []
  @Input() listWidget: Widget[] = [];
  @Output() onSubmitData: any = new EventEmitter<any>();
  
  // array widget selected
  widgetSelecteds: any[] = [];
  // all widget
  allWidgets: any[] = [];
  searchParamsDataSource: SearchParams
  searchParamsDashboard: SearchParams
  searchParamsWidget: SearchParams
  buttonType = ButtonType;
  checkAllMode = CheckAllMode;
  SYSTEM_SETTING = SYSTEM_SETTING;
  UPDATE_DATA = UPDATE_DATA;
  BUTTON = BUTTON;
  HOME = HOME;
  TEXT_COMMON = DATA_UPDATE;
  isMail: boolean;
  isAllowSyncWidget: boolean = false;
  isAdminOrSupporter: boolean = false;
  isLoadingWidgetTable: boolean = false;
  isTableDashLoading: boolean = false;
  isLoading: boolean = true;
  widgetGetSearchCd: string = '';
  dashboardGetSearchName: string = '';
  datasourceSearchCd: string = '';
  searchValue: string = '';
  ids: any[] = []
  loadicon: boolean = true;
  checked: boolean = false;
  process: any[] = [];
  status: string; 
  widgetCdSelected: any[] = []
 
  // is loading chart
  telNum: any;
  isInvalidTelNum: any;
  errorTelMsg: any;
  tableData: TableData;
  textVal: any;
  dataaaa: any[];
  groupedPeriod = [];
  isSupporterAdmin: boolean = false;
  filteredTable: TableData;
  rawData: any;
  allData: any;
  onCheckedList: any[];
  disableRadioBtn: boolean = false;
  disableSyncBtn: boolean = false;
  isOverDatasourceSelected: boolean = false;
  mapData: syncWidgetModel[] = [];

  _sharedDataService: SharedDataService;

  DEFAULT_VALUE: string = '全て';
  iconType = ButtonIconType;
  isDarkMode: boolean = false;

  constructor(
    private errorHandleService: ErrorHandleService,
    private loadingService: ProcessLoadingService,
    private datasourceService: DataSourceService,
    private authenService: AuthenticationService,
    private sharedDataService: SharedDataService,
    private syncLogDataService: SyncLogDataService,
    private mstService: MstCommonService,
    private corpMstService: CorpMstService,
    private handleSyncDataDataService: HandleSyncDataDataService,
    private dashboardService: DashboardService,
    private saucerLogService: SaucerLogService,
    private widgetUpdateService: WidgetUpdateService
  ) {
    this._sharedDataService = sharedDataService;
    const bodyElement = document.getElementsByTagName("body")[0];
    this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    
   }

  async ngOnChanges(changes: SimpleChanges) {
    if(changes["isDisplayModal"] && changes["isDisplayModal"].currentValue == true) {
      this.disableSyncBtn = true;
      this.isOverDatasourceSelected = false;
      let isSyncing = this.handleSyncDataDataService.inProgress;
      this.tableData.body = [];
      this.filteredTable.body = [];
      this.searchParamsDataSource.dataSource = [];
      this.searchParamsDataSource.defaultValue = "";
      this.searchParamsDataSource.dataSource = [];
      this.searchParamsWidget.defaultValue = "";
      this.searchParamsDashboard.dataSource = [];
      this.searchParamsDashboard.defaultValue = "";

      await this.initData();
      this.getLogSyncDataOnInit();
      if(!isSyncing)
        this.cleanAllProcess();
      await this.setDefaultValueForFilterBox();
    }
  }

  async setDefaultValueForFilterBox() {
    this.searchParamsDataSource.defaultValue = this.DEFAULT_VALUE;
    this.searchParamsWidget.defaultValue = this.DEFAULT_VALUE;
    this.searchParamsDashboard.defaultValue = this.DEFAULT_VALUE;
  }

  async ngOnInit(): Promise<void> {
    this.isAdminOrSupporter = await this.authenService.isAdminOrSupporter();
    this.searchParamsDashboard = this.createSearchParams();
    this.searchParamsWidget = this.createSearchParams();
    this.searchParamsDataSource = this.createSearchParams();
        
    this.searchParamsDashboard.comboDisplayText = 'name';
    this.searchParamsDashboard.placeholder = COMMON_TEXT.SEARCH_DLG;
    this.searchParamsWidget.comboDisplayText = 'name';
    this.searchParamsWidget.placeholder = COMMON_TEXT.SEARCH_DLG;
    this.searchParamsDataSource.comboDisplayText = 'name';
    this.searchParamsDataSource.placeholder = COMMON_TEXT.SEARCH_DLG;

    const masterCorpTask = await this.corpMstService.getAll();

    await this.setGroupedPeriod(masterCorpTask)

    this.configTableDashboard();
    this.filteredTable = cloneDeep(this.tableData);
  }

  checkProcess() {
    this.disableSyncBtn = false;
    this.disableRadioBtn = false;
  }

  cleanAllProcess() {
    let temptableDataBody = cloneDeep(this.tableData.body);
    let filterable = cloneDeep(this.tableData.body);

    this.tableData.body = temptableDataBody.map((e: any) => {return {...e, process: SyncDataStatus.NOT_UPDATED, checked: false}})
    this.filteredTable.body = filterable.map((e: any) => {return {...e, process: SyncDataStatus.NOT_UPDATED, checked: false}})

    this.bindingSearchDataToTable();
  }

  async initData() {
    this.loadingService.isLoading.emit(true);
    this.loadicon = true;
    let updateTable =  await this.datasourceService.getDataUpdate(this.isAdminOrSupporter);

    if(updateTable.statuscode === 200 && updateTable.data && updateTable.data.length > 0) {
      const [dashboards, widgets, datasources] = this.remapUpdateDataTable(updateTable.data);
      this.searchParamsDataSource.dataSource = datasources
        .map(item => ({ name: item.datasourceName, value: item.datasourceCd }))
        .filter(e => e.name !== "")
        .filter((element, index, self) => 
          index === self.findIndex((t) => (
            t.name === element.name && t.value === element.value
          ))
      );
      this.searchParamsWidget.dataSource = widgets
        .map(item => ({ name: item.widgetName, value: item.widgetCd }))
        .filter(e => e.name !== "")
        .filter((element, index, self) => 
          index === self.findIndex((t) => (
            t.name === element.name && t.value === element.value
          ))
      );
      this.searchParamsDashboard.dataSource = dashboards.filter(item => item !== "")
        .map(item => ({ name: item, value: item }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
        .filter((element, index, self) => 
          index === self.findIndex((t) => (
            t.name === element.name && t.value === element.value
          ))
      );

      this.searchParamsDataSource.dataSource.unshift({ 'name': this.DEFAULT_VALUE, 'value': '' })
      this.searchParamsWidget.dataSource.unshift({ 'name': this.DEFAULT_VALUE, 'value': '' })
      this.searchParamsDashboard.dataSource.unshift({ 'name': this.DEFAULT_VALUE, 'value': '' })
      this.allData = cloneDeep(updateTable.data)
      this.rawData = cloneDeep(updateTable.data)
      this.mapData = updateTable.data.map((ele: any) => {
        return {
          widgetname: ele.widgetname,
          widgetcd: ele.widgetcd,
          dsName: ele.dsName,
          datasourceCd: ele.datasourceCd,
          dashboardname: ele.dashboardname,
          relatedwidgetnames:  this.getRelatedWidget(ele.datasourceCd, ele.widgetcd),
          staff: ele.staff,
          date: ele.date,
          status: null,
          process: "0",
          checked: false,
          statusMessage: "",
          id: ele.id
        };
      });
     
      this.tableData.body = cloneDeep(this.mapData?.filter(e => !(e.widgetname == "")));
      this.filteredTable = cloneDeep(this.tableData);

      this.searchParamsDataSource = cloneDeep(this.searchParamsDataSource);
      this.searchParamsWidget = cloneDeep(this.searchParamsWidget);
      this.searchParamsDashboard = cloneDeep(this.searchParamsDashboard);
    }
    this.loadingService.isLoading.emit(false);
  }

  async setGroupedPeriod(allCorpMst: ResponseData<CorpMstModel[]>) {
    let startMonth = 1;
    if (allCorpMst.statuscode == 200) {
      let monthSetting = allCorpMst.data?.find(x => x.contentcd == "0001");
      startMonth = +(monthSetting?.value || 1);
    }
    this.groupedPeriod = await this.mstService.getMstFilter({ startmonth: startMonth });
  }

  getRelatedWidget(datasourceCd: string, widgetCd: string): string  {
    let widgetNames: string = "";
    try {
      let widgetSameDatasource: any = cloneDeep(this.allData.filter((e: any) =>  e.datasourceCd === datasourceCd && e.widgetcd !== widgetCd).map((obj: any) => obj.widgetname));
      widgetSameDatasource = widgetSameDatasource?.filter((value: any, index: any, self: any) => 
        self.indexOf(value) === index
      );
      widgetNames = widgetSameDatasource;
    }
    catch(error) {
      widgetNames = "";
    }
    return widgetNames;
  }

  remapUpdateDataTable(updateData: any): [dashboards: any[], widgets: any[], datasources: any[]] {
    let handleData = cloneDeep(updateData);
    let dashboards: any[] = [];
    let widgets: any[] = [];
    let datasources: any[] = [];

    dashboards = Array.from(new Set(handleData.map((e: any) => e.dashboardname)));
    widgets = Array.from(new Set(handleData.map((e: any) => ({widgetName: e.widgetname, widgetCd: e.widgetcd}))));
    datasources = Array.from(new Set(handleData.map((e: any) => ({datasourceName: e.dsName, datasourceCd: e.datasourceCd}))));

    const uniqueDatasources = handleData.reduce((acc: any, e: any) => {
      const isDuplicate = acc.some((item: any) =>
        item.datasourceName === e.dsName && item.datasourceCd === e.datasourceCd
      );
    
      if (!isDuplicate) {
        acc.push({ datasourceName: e.dsName, datasourceCd: e.datasourceCd });
      }
    
      return acc;
    }, []);
    
    datasources = uniqueDatasources;

    return [dashboards, widgets, datasources];
  }
  
  createSearchParams() {
    return {
      type: SearchType.combo,
      cssStyle: '',
      readonly: false,
      disabled: false,
      maxLength: 100,
      defaultValue: '',
      comboDisplayText: 'name'
    };
  }
  
  onClose() {
    this.resetHeaderStyle();
    this.isDisplayModal = false;
    this.isAllowSyncWidget = false;
    this.onSubmitData.emit(null);
    this.dashboardGetSearchName = "";
    this.widgetGetSearchCd = "";
    this.datasourceSearchCd = "";

    if (!this.handleSyncDataDataService.inProgress) {
      LocalStorageHelper.setPackCd("");
      LocalStorageHelper.setSyncData(null);
      this._sharedDataService.removeItem(sharedDataKey.SYNCLOGS);
    }
  }

  async onSync() {
    this.disableRadioBtn = true;
    this.disableSyncBtn = true;
    this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.DATA_SYNC_FAIL);
    let selectedData = this.tableData.body.filter(x => x.checked === true);
    this.handleBeforeSync(selectedData);
    let logModel = this.createLogModelForWrite(selectedData);
    selectedData = this.remapSelectedData(selectedData);
    let resLog = await this.syncLogDataService.writeLogs(logModel);
    let syncData = this.mapSyncDataModel(logModel, selectedData, resLog.data);
    if (this.isAllowSyncWidget) {
      const selectedAllowSyncWidgetLog = selectedData.map((x:any) => (
        { 
          datasourceCd: x.datasourceCd,
          dsName: x.dsName,
          dashboardname: x.dashboardname,
          widgetcd: x.widgetcd,
          widgetname: x.widgetname,
          officeidlst: x.officeidlst
        }
      ));
      this.saucerLogService.action(
        {
          content: CONTENT_LOG.SYNC_LASTED_DATA + JSON.stringify(selectedAllowSyncWidgetLog)
        }, 
        { 
          action: SAUCER_LOG_ACTION.HOME.SYNC_LATEST_DATA
        }
      );
    } else {
      const selectedNotAllowSyncWidgetLog = selectedData.map((x:any) => (
        { 
          datasourceCd: x.datasourceCd,
          dsName: x.dsName,
          officeidlst: x.officeidlst
        }
      ));
      this.saucerLogService.action(
        {
          content: CONTENT_LOG.SYNC_LASTED_DATA + JSON.stringify(selectedNotAllowSyncWidgetLog)
        }, 
        { 
          action: SAUCER_LOG_ACTION.HOME.SYNC_LATEST_DATA
        }
      );
    }
    this.datasourceService.syncData(syncData, resLog.data).subscribe(
      (res: any) => {
        const apiPath = 'datasource' + API_APP.DATASOURCE.SYNCDATA + "/" + resLog.data;
        if (res.statuscode === 200) {
          this.saucerLogService.system({
            apiPath,
            statusCode: res.statuscode,
            body: JSON.stringify(syncData),
            content: JSON.stringify(resLog.data)
          }, { 
            action: SAUCER_LOG_ACTION.HOME.SYNC_LATEST_DATA
          });
        } else {
          this.saucerLogService.error({
            apiPath,
            statusCode: res.statuscode,
            body: JSON.stringify(syncData),
            content: JSON.stringify(resLog.data)
          }, { 
            action: SAUCER_LOG_ACTION.HOME.SYNC_LATEST_DATA
          });
        }
      }
    );
    LocalStorageHelper.setPackCd(resLog.data);
    const widgetSynchronizing = [...new Set(syncData.flatMap(item => item.relatedwidgetlist))];
    const dsstructcdSynchronizing = [...new Set(syncData.map(item => item.dsstructcd))];
    this.widgetUpdateService.changeSynchronizing({widget: widgetSynchronizing, dsstructcd: dsstructcdSynchronizing});
    this.handleSyncDataDataService.repeatSyncData();
    this.handleSyncDataDataService.clearErrorDatasourceCds();
  }

  remapSelectedData(selectedData: any): DatasourceSyncDataModel[] {
    let result: any[] = [];
    let handleData = selectedData.map((ele: any) => {
      let modelData: DatasourceSyncDataModel = new DatasourceSyncDataModel();
      let currentData = this.rawData.find((e: any) => e.widgetcd === ele.widgetcd);

      if(currentData) {

        let startDate = '';
        let endDate = '';
        if(currentData.period?.includes('~')){
          let codePeriod = currentData.period?.split('~');
          startDate = codePeriod[0];
          endDate = codePeriod[1];
        }
        else {
          let codePeriod = currentData.period?.split('-');
          if (codePeriod) {
            let groupcd = codePeriod[0];
            let valuecd = currentData.period;
    
            this.groupedPeriod.filter((item: any) => {
              if (item.value === groupcd) {
                let selectedTime = item.items?.find((time: any) => time.value === valuecd);
                if (selectedTime) {
                  startDate = currentData.dstype == DataSourceType.SEIKUY ? selectedTime.startdateseikuy : selectedTime.startdate;
                  endDate = currentData.dstype == DataSourceType.SEIKUY ? selectedTime.enddateseikuy : selectedTime.enddate;
                }
              }
            })
          }
        }

        modelData = {
          ...currentData,
          checked: true,
          startdate: startDate,
          enddate: endDate
        }
      }

      return modelData;
    })

    result = handleData;
    return result;
  }

  mapSyncDataModel(data: SyncLogDataModel[], selectedData: DatasourceSyncDataModel[], packCd: string) {
    let res: Array<DatasourceSyncModel> = new Array<DatasourceSyncModel>();
    data.forEach((element) => {
      let currentSelectedData = selectedData.filter(e => e.datasourceCd === element.dsstructcd);
      let resItem = new DatasourceSyncModel();
      resItem.id = element.id;
      if (!element.id) resItem.id = uuid();
      resItem.packcd = packCd;
      resItem.dsstructcd = element.dsstructcd;
      resItem.dsname = currentSelectedData ? currentSelectedData[0].dsName : "";
      resItem.officeidlst = currentSelectedData ? currentSelectedData[0].officeidlst! : "";
      resItem.dstype = currentSelectedData ? currentSelectedData[0].dstype : 0;
      resItem.startdate = currentSelectedData ? currentSelectedData[0].startdate! : "";
      resItem.enddate = currentSelectedData ? currentSelectedData[0].enddate! : "";
      resItem.period = currentSelectedData ? currentSelectedData[0].period! : "";
      resItem.aggregationtype = currentSelectedData ? currentSelectedData[0].aggregationtype : "";
      resItem.dspblockunit = currentSelectedData ? currentSelectedData[0].dspblockunit : false;
      resItem.dspageflg = currentSelectedData ? currentSelectedData[0].dspageflg : false;
      resItem.lapsedday = currentSelectedData ? currentSelectedData[0].lapsedday : undefined;
      resItem.relatedwidgetlist = this.isAllowSyncWidget ? 
                                this.getRelatedWidgetByDatasource(element.dsstructcd)
                                : this.getListRelatedWidgetFromSelectedItems(element.dsstructcd, selectedData);

      res.push(resItem);
    });
    return res;
  }

  getListRelatedWidgetFromSelectedItems(datasourceCd: string, selectedData: DatasourceSyncDataModel[]) {
    let result: string[] = []
    result = selectedData.map((obj: any) => {
      if(obj.datasourceCd === datasourceCd)
        return obj.widgetcd;
    }).filter((e: any) => e).filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    return result;
  }

  getRelatedWidgetByDatasource(datasourceCd: string) {
    let result: string[] = [];
    let widgetUseSameDatasource = this.mapData?.filter((e: any) => e.datasourceCd === datasourceCd);

    if(widgetUseSameDatasource.length > 0) {
      result = widgetUseSameDatasource.map((e: any) => e.widgetcd).filter((value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
      });
    }
    return result;
  }

  createLogModelForWrite(selectedData: any) {
    let res: Array<SyncLogDataModel> = new Array<SyncLogDataModel>();
    const hasHandleDatasourceCds: string[] = [];
    const uniqueRequestData = selectedData.filter((obj: any) => {
      if (!hasHandleDatasourceCds.includes(obj.datasourceCd)) {
        hasHandleDatasourceCds.push(obj.datasourceCd)
        return true;
      }
      return false;
    });
    uniqueRequestData.forEach((element: any) => {
      let resItem = new SyncLogDataModel();
      resItem.id = uuid();
      resItem.upddate = element.upddate;
      resItem.updstfcd = element.updstfcd;
      resItem.insdate = element.insdate;
      resItem.insstfcd = element.insstfcd;
      resItem.delflg = element.delflg;
      resItem.packcd = "";
      resItem.dsstructcd = element.datasourceCd;
      resItem.querystr = element.querystr || "";
      resItem.status = 1;
      res.push(resItem);
    });

    return res;
  }

  handleBeforeSync(selectedData: any) {
    this._sharedDataService.setItem(sharedDataKey.PRESYNCLOGS, null);
    this._sharedDataService.setItem(sharedDataKey.SYNCLOGS, null);

    let listWidgetOnSync = selectedData.map((e: any) => e.widgetcd);
    this.handleSyncDataDataService.setListWidgetOnSync(listWidgetOnSync);

    let baseData = cloneDeep(this.tableData.body);
    this.tableData.body = baseData.map((e: any) => {
      if(e.checked == true) {
        return {
          ...e, 
          process: SyncDataStatus.UPDATING.toString(),
          statusMessage: SYNCDATA_STATUS.UPDATING
        }
      }
      else
        return {...e};
    });
    this.tableData = cloneDeep(this.tableData);

    let filteredData = cloneDeep(this.filteredTable.body);
    this.filteredTable.body = filteredData.map((e: any) => {
      if(e.checked == true) {
        return {
          ...e, 
          process: SyncDataStatus.UPDATING.toString(),
          statusMessage: SYNCDATA_STATUS.UPDATING
        }
      }
      else  
        return {...e};
    });
    this.filteredTable = cloneDeep(this.filteredTable);
  }
  
  onFilterwidgetData(dataWidgetSelected: any){
    this.widgetSelecteds = dataWidgetSelected;
    this.widgetGetSearchCd =dataWidgetSelected && dataWidgetSelected.length > 0 ? dataWidgetSelected[0].value : "";
  }

  onFilterDashboardData(dataDashboardSelected: any) {
    this.dashboardGetSearchName = dataDashboardSelected && dataDashboardSelected.length > 0 ?  dataDashboardSelected[0].value : "";
  }

  async onSearch() {
    this.isLoadingWidgetTable = true;
    this.isOverDatasourceSelected = false;
    //Get API Filter 
    let filteredDataFilter = await this.datasourceService.getTableWithFilter(this.datasourceSearchCd, this.widgetGetSearchCd, this.dashboardGetSearchName ,this.isAdminOrSupporter);
    if(filteredDataFilter.statuscode == 200 && filteredDataFilter.data){
      this.rawData = filteredDataFilter.data;
      let mapData: syncWidgetModel[] = filteredDataFilter.data.map((ele: any) => {
        return  {
          widgetname: ele.widgetname,
          widgetcd: ele.widgetcd,
          dsName: ele.dsName,
          datasourceCd: ele.datasourceCd,
          dashboardname: ele.dashboardname,
          relatedwidgetnames: this.getRelatedWidget(ele.datasourceCd, ele.widgetcd),
          staff: ele.staff,
          date: ele.date,
          status: null,
          process: "0",
          checked: false,
          statusMessage: "",
          id: ele.id
        };
      });
      this.tableData.body = mapData.filter(e => !(e.widgetname == ""));
    }
    this.filteredTable = cloneDeep(this.tableData);
    let hasSelectedWidget = this.filteredTable.body.filter((a: any) => a.checked == true);
    this.checkIsSelectedOverRecords(hasSelectedWidget);
    this.isLoadingWidgetTable = false;
    this.disableSyncBtn = true;
  }

  onFilterDatasourceData(event: any) {
    this.datasourceSearchCd = event && event.length > 0 ? event[0].value : "";
  }
  
  checkRowsDashboardTable(event: any) {
    let isOnProcess = this.handleSyncDataDataService.inProgress;

    (event.data.length > 0 && !isOnProcess) ? this.disableSyncBtn = false :this.disableSyncBtn = true;
    this.checkIsSelectedOverRecords(event.data);

    let updateCd = event.data.map((ele: any) => ele.widgetcd);
    this.filteredTable.body = cloneDeep(this.filteredTable.body.map((ele: any) => {
      if(updateCd.includes(ele.widgetcd))
      {
        return {
          ...ele,
          checked: true
        }
      }
      else {
        return {
          ...ele,
          checked: false
        }
      }
    }));

    this.tableData.body = cloneDeep(this.tableData.body.map((ele: any) => {
      if(updateCd.includes(ele.widgetcd))
      {
        return {
          ...ele,
          checked: true
        }
      }
      else {
        return {
          ...ele,
          checked: false
        }
      }
    }))

    this.changeStyleHeader();
  }

  changeStyleHeader() {
    this.filteredTable?.header?.map((ele: any, index: number) => {
      if (index == 1) { //index of dsName header.
        if (this.isOverDatasourceSelected) {
          ele.attribute.header.color = "#ff0000";
          ele.attribute.header['font-weight'] = "bold";
        } else {
          ele.attribute.header.color = "#fff";
          ele.attribute.header['font-weight'] = "";
        }
      }
      return ele;
    });
  }

  resetHeaderStyle() {
    this.filteredTable?.header?.map((ele: any, index: number) => {
      if (index == 1) { //index of dsName header.
          ele.attribute.header.color = "#fff";
          ele.attribute.header['font-weight'] = "";
      }
      return ele;
    });
  }

  checkIsSelectedOverRecords(data: any) {
    if(data && data.length > 0) {
      let uniqueSelectedDatasource  = new Set(data.map((obj: any) => obj.datasourceCd));
      this.isOverDatasourceSelected = uniqueSelectedDatasource.size > 20 ? true : false;
      if (this.isOverDatasourceSelected) {
        this.changeStyleHeader();
      } else {
        this.resetHeaderStyle();
      }
    } else {
      this.resetHeaderStyle();
      this.isOverDatasourceSelected = false;
    }
  }

  configTableDashboard() {
    this.tableData = {
      config: {
        id: 'dashboard-1',
        caption: '',
        emptyMessage: COMMON_TEXT.NO_DATA,
        showIndex: false,
        showCheckbox: false,
        showPagination: false,
        hoverShowCheckbox: true,
        hoverBoderNone: true,
        isResponsive: true,
        isSelectedRow: true,
        tableStyle: { 'margin-top': '4px', 'font-weight': 'unset !important',border:'none' },
        inlineTableStyle: { 'table-layout': 'fixed', 'width': '100%',border:'none' },
        isAllowCheckedAllInRow: true
      },
      header: [
        {
          field: 'widgetname',
          title: 'ウィジェット名称',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%',border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row'
          },
          dataType: DataType.TEXT,
          isloadicon: false,
          visible: true
        },
        {
          field: 'dsName',
          title: 'データソース名称',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '30%', border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row'
          },
          dataType: DataType.TEXT,
          isloadicon: false,
          visible: true
        },
        {
          field: 'dashboardname',
          title: 'ダッシュボード',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%',border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row'
          },
          dataType: DataType.TEXT,
          isloadicon: false,
          visible: true
        },
        {
          field: 'relatedwidgetnames',
          title: '関連ウィジェット',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '20%',border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row',
          },
          dataType: DataType.TEXT,
          visible: true,
          isloadicon: false,
          isUseTooltips: true
        },
        {
          field: 'staff',
          title: '更新者',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '12%',border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row-center'
          },
          dataType: DataType.TEXT,
          isloadicon: false,
          visible: true
        },
        {
          field: 'date',
          title: '最終更新日',
          sortable: false,
          filterable: false,
          attribute: {
            header: { width: '15%',border:'none'},
            row: {border:'none'},
            rowClass: 'widget-dialog-row-center'
          },
          dataType: DataType.TEXT,
          isloadicon: false,
          visible: true
        },
        {
          field: 'status',
          title: '進捗',
          sortable: false,
          filterable: false,
          attribute: {
            header: { border:'none', width: '8%', 'border-radius': '0px 6px 0px 0px ','border-color':'#676666'},
            row: {border:'none' },
            rowClass: 'widget-dialog-row-center'
          },
          dataType: DataType.IMG,
          isloadicon: this.loadicon,
          visible: true
        }
      ],
      body: [
      ]
    };
  }

  onCheckbox(status: boolean) {
    if(this.isAllowSyncWidget !== status)
   this.isAllowSyncWidget = status;
  }

  getLogSyncDataOnInit() {
    let packCd = LocalStorageHelper.getPackCd();
    if (packCd && !this.handleSyncDataDataService.inProgress) {
       this.handleSyncDataDataService.repeatSyncData();
    }
    this._sharedDataService.data$.subscribe(res => {
      if (res && res.key == 'eventSyncLogData') {
        this.mapProgress();
      }
    });
  }

  async mapProgress(isOnInit: boolean = false) {
    let dataLogs: any[];
    if (isOnInit) {
      dataLogs = LocalStorageHelper.getSyncData();
    } else {
      dataLogs = this._sharedDataService.getItem(sharedDataKey.SYNCLOGS);
    }
    if (dataLogs) {
      let widgetSyncingList: string[] = this.handleSyncDataDataService.getListWidgetOnSync();
      let tempTable = cloneDeep(this.tableData.body);
      for await (const element of tempTable) {
        let tmp = dataLogs.find(x => x.dsstructcd === element.datasourceCd);
        if (tmp && widgetSyncingList.includes(element.widgetcd)) {
          element.checked = true;
          switch (tmp.status) {
            case SyncDataStatusBackEnd.WAITING:
              element.statusMessage = SYNCDATA_STATUS.UPDATING;
              element["statusMessage-subText"] = "";
              element.process = SyncDataStatus.UPDATING;
              break;
            case SyncDataStatusBackEnd.INPROGRESS:
              element.statusMessage = SYNCDATA_STATUS.UPDATING;
              element.process = SyncDataStatus.UPDATING;
              element["statusMessage-subText"] = "";
              break;
            case SyncDataStatusBackEnd.FAIL:
              element.statusMessage = SYNCDATA_STATUS.ERROR;
              element.process = SyncDataStatus.ERROR;
              element["statusMessage-subText"] = tmp.errortype == 1 ? MESSAGE_TEXT.DS_OVER_LIMIT_RECORDS : "";
              element.lastsync = tmp.lastsync ? (moment(tmp.lastsync)).format(DateFormat.FULL_SHORT_DATE) : element.lastsync;
              break;
            case SyncDataStatusBackEnd.SUCCEED:
              element.statusMessage = SYNCDATA_STATUS.UPDATE_COMPLETE;
              element.process = SyncDataStatus.UPDATE_COMPLETE;
              element.lastsync = tmp.lastsync ? (moment(tmp.lastsync)).format(DateFormat.FULL_SHORT_DATE) : element.lastsync;
              element["statusMessage-subText"] = "";
              break;
            default:
              element.statusMessage = SYNCDATA_STATUS.NOT_UPDATED;
              element.process = SyncDataStatus.NOT_UPDATED;
              element["statusMessage-subText"] = "";
              break;
          }
        }
      }

      let tempFilteredTable = cloneDeep(this.filteredTable.body);
      for await (const element of tempFilteredTable) {
        let tmp = dataLogs.find(x => x.dsstructcd === element.datasourceCd);
        if (tmp && widgetSyncingList.includes(element.widgetcd)) {
          element.checked = true;
          switch (tmp.status) {
            case SyncDataStatusBackEnd.WAITING:
              element.statusMessage = SYNCDATA_STATUS.UPDATING;
              element["statusMessage-subText"] = "";
              element.process = SyncDataStatus.UPDATING;
              break;
            case SyncDataStatusBackEnd.INPROGRESS:
              element.statusMessage = SYNCDATA_STATUS.UPDATING;
              element.process = SyncDataStatus.UPDATING;
              element["statusMessage-subText"] = "";
              break;
            case SyncDataStatusBackEnd.FAIL:
              element.statusMessage = SYNCDATA_STATUS.ERROR;
              element.process = SyncDataStatus.ERROR;
              element["statusMessage-subText"] = tmp.errortype == 1 ? MESSAGE_TEXT.DS_OVER_LIMIT_RECORDS : "";
              element.lastsync = tmp.lastsync ? (moment(tmp.lastsync)).format(DateFormat.FULL_SHORT_DATE) : element.lastsync;
              break;
            case SyncDataStatusBackEnd.SUCCEED:
              element.statusMessage = SYNCDATA_STATUS.UPDATE_COMPLETE;
              element.process = SyncDataStatus.UPDATE_COMPLETE;
              element.lastsync = tmp.lastsync ? (moment(tmp.lastsync)).format(DateFormat.FULL_SHORT_DATE) : element.lastsync;
              element["statusMessage-subText"] = "";
              break;
            default:
              element.statusMessage = SYNCDATA_STATUS.NOT_UPDATED;
              element.process = SyncDataStatus.NOT_UPDATED;
              element["statusMessage-subText"] = "";
              break;
          }
        }
      }

      this.filteredTable.body = tempFilteredTable;
      this.tableData.body = tempTable;
      this.bindingSearchDataToTable();
      if (!this.handleSyncDataDataService.inProgress) {
        setTimeout(() => {
          this.disableRadioBtn = false;
          this.disableSyncBtn = false;
        }, 2000);
      }
      else {
        this.disableRadioBtn = true;
        this.disableSyncBtn = true;
      }
    }
  }

  bindingSearchDataToTable() {
    this.tableData = cloneDeep(this.tableData);
    this.filteredTable = cloneDeep(this.filteredTable);
  }
}
