import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import * as gridster from 'angular-gridster2';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { OfficeAPIService } from 'src/app/services/modules/office.service';
import { LocalStorageHelper, LocalStorageKey } from '../../../app/_helper/local-storage.helper';
import { ROUTE_PATH } from '../../../app/const/route-path';
import { BUTTON, COMMON_TEXT, DATA_UPDATE, HOME, SYSTEM_SETTING } from '../../../app/const/text-common';
import { ButtonType, DataSourceType, DateFormat, DeviceType, GraphType, LambdaStatus, SearchType, SyncStatusWidgetResult, WidgetSelectDataType } from '../../../app/enum/common-enum';
import { ButtonItem, ModalParam, ModalTemplate, SearchParams } from '../../../app/models/common-model';
import { Dashboard, DashboardSetting } from '../../../app/models/response/dashboard.ro';
import { Widget, CHART_TYPE } from '../../../app/models/response/widget.ro';
import { DetectDeviceService, IDeviceType } from '../../../app/services/detect-device.service';
import { ProcessLoadingService } from '../../../app/services/loading.service';
import { DashboardService } from '../../../app/services/modules/dashboard.service';
import { DataSourceService } from '../../../app/services/modules/data-source.service';
import { WidgetService } from '../../../app/services/modules/widget.service';
import { SidebarExpandHandleService } from '../../../app/services/sidebar-expand-handle.service';
import Utils from '../../../app/util/utils';
import { ZaitakuItemDetailName, ZaitakuItemDetails } from 'src/app/const/zaitaku-table-define';
import { ZaitakuService } from 'src/app/services/modules/zaitaku.service';
import { ZaitakuSyncItem } from 'src/app/models/response/zaitaku.ro';
import { HelpListService } from '../../../app/services/modules/help-list.service';
import { AuthenticationService } from '../../../app/services/authentication.service';
import { HelpListHelper } from '../../../app/_helper/help-list';
import { SystemSettingPage } from 'src/app/const/const';
import { DialogService } from 'primeng/dynamicdialog';
import { DialogModalComponent } from 'src/app/component/common/dialog-modal/dialog-modal.component';
import { WidgetResultService } from 'src/app/services/modules/widget-result.service';
import {ErrorHandleService} from 'src/app/services/error-handle.service';
import {ERROR_TEXT, FUNCTION_TITLE_TEXT} from 'src/app/const/error-text';
import {Subscription} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {FilterConditionRequest, WidgetResultRequest } from '../../../app/models/request/widget.dto';
import * as XLSX from 'xlsx-js-style';
import { Office } from 'src/app/models/response/office.ro';
import { DashboardFilterService } from '../../../app/services/dashboard-filter.service';
import { CONTENT_LOG, SAUCER_LOG_ACTION } from 'src/app/config/saucer-log.config';
import { SaucerLogService } from 'src/app/services/saucer-logs/saucer-log.service';
import { API_APP } from 'src/app/config/app.config';
import { readTableConfig } from '../../_helper/helper';
import { DefaultValueSettingService } from 'src/app/services/modules/default-value-setting.service';
import { ClearProcessService } from 'src/app/services/clear-process.service';
import { StepFunctionService } from 'src/app/services/modules/step-function.service';
import { WidgetUpdateService } from 'src/app/services/widget-update.service';

@Component({
	selector: 'pivot-dashboard',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
	isDarkMode: boolean = true;

	BUTTON = BUTTON;
	HOME = HOME;
	COMMON_TEXT = COMMON_TEXT;
	DATA_UPDATE = DATA_UPDATE;
	DeviceType = DeviceType;
	ButtonType = ButtonType;
	deviceType!: string;
	heightDashboard: string | undefined;
	currentSelectedDashboard: Dashboard;
	gridster!: gridster.GridsterComponent;
	options!: gridster.GridsterConfig;
	isUpdateData: boolean = false;
	isHasZaitaiku: boolean =  false;
	isDspConfirmInviteModal: boolean = false;
	isFilterByOffice: boolean = false;
	modalData: ModalTemplate = new ModalTemplate();
	currentdasboard: any;
	// dashboardCD
	dashboardCd: string = '';
	dataUpdate: any[] = [];
	// list Dashboard Setting
	dashboards: any[] = [];
	// is loading chart
	isLoading: boolean = true;
	tableChart = GraphType.TABLE_CHART;
	// list period
	periods: any[] = [];
	isOpenDetail: boolean = false;
	isSortClick: boolean = false;
	//disable button
	dsbBtnSelectOffice: boolean = false;
  	countOffice :number = 0;
	isResetOffice: boolean = false;
	isDspOfficeModal: boolean = false;
	officeSelecteds: Array<Office> = [];
	officeSelected : string[] = [];
	filterCondition: FilterConditionRequest = new FilterConditionRequest();
	checkOfficeSeletedLocalStorage: string | null;

	searchParams: SearchParams = {
		type: SearchType.combo,
		cssStyle: { width: 'min(100%, 583px)', height: '40px' },
		readonly: false,
		disabled: false,
		maxLength: 100,
		defaultValue: '',
		comboDisplayText: 'name'
	};

	listWidget: Widget[] = [];
	listDashboard: Dashboard[] = [];
	officeList: any[] = [];
	dataZaitaku: ZaitakuSyncItem[] = [];
	hasHospitalNearby: boolean = false;
	currentZaitakuOfficeNm: string = "";
  //help 
	listDataSourceCd:any[] = [];
	showHelpDialog = false;
	lstHelp: any[] = [];
	listDatasourceByOffice: DataSourceService[] = [];
	dataHelpPopup: any = {};
	checkHidePopup = false;
	isAdminOrSupporter: boolean = false;
	sortData: any = null;
	zaitakuResponse:any;
	themeOptions: string[]

	apiServiceSubscriber: Subscription | undefined;
	isUnauthorizedError: boolean = false;
	syncStatusIconSrc: string = "";
	syncStatusWidget = SyncStatusWidgetResult ;
	reSyncDatasourcecds: string[] = [];

	//#region Log
	SAUCER_LOG_ACTION = SAUCER_LOG_ACTION;
	//#endregion

	constructor(
		private router: Router,
		private modalService: DialogService,
		private detectDeviceService: DetectDeviceService,
		private sidebarExpandHandleService: SidebarExpandHandleService,
		private dashboardService: DashboardService,
		private widgetService: WidgetService,
		private loadingService: ProcessLoadingService,
		private dataSourceService: DataSourceService,
		private widgetResultService: WidgetResultService,
		private officeService: OfficeAPIService,
		private zaitakuService: ZaitakuService,
		private helpListService: HelpListService,
		private authenService: AuthenticationService,
		private errorHandleService: ErrorHandleService,
		private dashboardFilterService: DashboardFilterService,
		private saucerLogService: SaucerLogService,
    private defaultValueSettingService: DefaultValueSettingService,
    private clearProcessService: ClearProcessService,
    private stepFunctionService: StepFunctionService,
		private widgetUpdateService: WidgetUpdateService
	) {
		this.detectDeviceService.currentDevice.subscribe((device: IDeviceType) => {
			if (device) this.deviceType = device.type;
		});
		const bodyElement = document.getElementsByTagName("body")[0];
    	this.isDarkMode = bodyElement.classList.contains("navi") || bodyElement.classList.contains("dark") ? true : false;
    	this.syncStatusIconSrc = this.isDarkMode ? 'dark': 'normal' ;
	}

  ngOnDestroy(): void {
		if(this.currentSelectedDashboard) {
			this.currentSelectedDashboard?.setting.map((x:any)=>{
				x.dataSource = [];
			})
			this.currentSelectedDashboard.setting = [];
			this.currentSelectedDashboard = new Dashboard();
		}	
		this.apiServiceSubscriber?.unsubscribe();
	}

	async ngOnInit(): Promise<void> {
		this.filterCondition.isFullData = true;
		this.officeService.setOfficeSelected([]);
		//this.officeService.setData([], LocalStorageKey.OFFICE_SELECTED);
		this.apiServiceSubscriber = this.errorHandleService.responseValue.subscribe((data: any) => {
			if(data.statuscode == 401) 
				this.isUnauthorizedError = true;
			else
				this.isUnauthorizedError = false;
		})
		this.isOpenDetail = false;
		this.isAdminOrSupporter = await this.authenService.isAdminOrSupporter();
		this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
		this.sidebarExpandHandleService.events$.forEach(() => {
			this.setOptions();
		});
		this.setOptions();
		this.loadingService.isLoading.emit(true);
		const offices = await this.officeService.getAll();
		if ((offices.data === null || (offices.data && offices.data.length === 0)) && this.helpListService.getCheckOffice() && this.isUnauthorizedError == false) 
		this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(false);
		const publicRes = await this.dashboardService.getPublic(this.isAdminOrSupporter);
		this.listDashboard = publicRes || [];
		await this.initZataku();
		await this.initData();
		this.saucerLogService.action({}, { 
			action: SAUCER_LOG_ACTION.HOME.VIEW 
		});
		this.searchParams.comboDisplayText = 'name';
		this.searchParams.dataSource = this.listDashboard;
		this.searchParams.defaultValue = this.listDashboard.length > 0 ? this.listDashboard[0]?.name : "";
		this.searchParams = cloneDeep(this.searchParams);
		this.loadWidgetContent();
		this.officeList = await this.officeService.getAllOfficeService();
		// this.checkOfficeSeletedLocalStorage = localStorage.getItem(LocalStorageKey.OFFICE_SELECTED);
		// if (this.checkOfficeSeletedLocalStorage)
		// {
		// 	this.themeOptions = JSON.parse(this.checkOfficeSeletedLocalStorage);
		// 	this.officeSelected = this.themeOptions
		// }else {
		// 	this.officeSelected = [];
		// }
		if (this.officeSelected.length > 0) {
			this.filterCondition.isFullData = false;
		} else {
			this.filterCondition.isFullData = true;
		}
		this.filterCondition.officesSelected = this.officeSelected;
		if((offices.data === null || (offices.data && offices.data.length === 0)) && this.helpListService.getCheckOffice() && this.isUnauthorizedError == false) {
			this.helpListService.setCheckPopUp(false);
			this.helpListService.setCheckOffice(false);
			let modalParam = new ModalParam();
			modalParam.description = COMMON_TEXT.CONFIRM_NO_OFFICE;
			let btnOk = new ButtonItem();
			btnOk.buttonText = COMMON_TEXT.SYNC_MASTER_DATA_LINK;
			btnOk.buttonType = ButtonType.HYPERLINK;
			this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
			btnOk.clickFunction = async () => {
				this.router.navigate([LocalStorageHelper.getUrl(ROUTE_PATH.SYSTEM_SETTING) + '/' + SystemSettingPage.MASTER_SYNC]);
			}
			modalParam.buttonArray = [btnOk];
			this.loadingService.isLoading.emit(false);
			this.modalService.open(DialogModalComponent, {
				header: COMMON_TEXT.WELCOME_TEXT,
				width: '30em',
				data: { modalParam },
			});
		} 
		else {
			if (this.helpListService.getCheckPopUp()) {
				this.helpListService.setCheckPopUp(false);
				await this.showHelpPoup();
			}
		}
		this.errorHandleService.setIsCloseErrorDialogOrErrorScreen(true);
		this.loadingService.isLoading.emit(false);

		//#region Reload widget after update-widget-dialog
		this.widgetUpdateService.currentSynced.subscribe(async data => {
			if(!data || !this.currentSelectedDashboard?.setting?.length) return;

			const widgets = this.currentSelectedDashboard.setting
				.filter(widget => widget.syncStatus == this.syncStatusWidget.DOING);
			if(!widgets) return;

			const widgetcds = widgets.map(x => x.widgetCd);

			//Await widget sync
			await this.widgetService.CheckSyncStatusIsLatest(widgetcds);

			//Update Period
			const widgetPeriod = await this.widgetService.getPeriodInfor(widgetcds, false);

			//WidgetResult
			const requestBody = {
				widgetCds: widgetcds,
				selectType: WidgetSelectDataType.SPECIALCASE,
				isTemplate: false,
				filterCondition: this.filterCondition
			} as WidgetResultRequest;
			const widgetResult = await this.widgetResultService.getByWidgetCds(requestBody);


			if(widgetPeriod.statuscode == 200 && widgetPeriod.data) {
				widgetPeriod.data.forEach((x:any) => {
					const widget = widgetResult.data.find((y:any) => x.widgetcd == y.widgetcd);
					if(widget)
					{
						widget.startdate = x.dstype == DataSourceType.SEIKUY? (moment(x.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(x.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
						widget.enddate = x.dstype == DataSourceType.SEIKUY? (moment(x.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(x.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
					}
				});
			}

			//Update resync button
			this.currentSelectedDashboard.setting
			.filter(widget => this.reSyncDatasourcecds.includes(widget.datasourceCd ?? "")).map(x => {
					x.syncStatus = this.syncStatusWidget.REQUEST_SYNC;
					return x;
				}
			);
			this.reSyncDatasourcecds = [];

			//Update widget
			widgets.map((x: DashboardSetting) =>  {
				const widget = widgetResult.data.find((y:any) => x.widgetCd == y.widgetcd);
				if(widget)
				{
					x.isLoadWidgetResultComplete = false;
					x.loading = true;
					x.widgetResult = widget.result;
					x.syncStatus = this.syncStatusWidget.LATEST;

					setTimeout(() => {
						x.isLoadWidgetResultComplete = true;
						x.loading = false;
					}, 500);
				}
				return x;
			});
		});
		this.widgetUpdateService.currentSynchronizing.subscribe(data => {
			if(!data || !this.currentSelectedDashboard?.setting?.length) return;

			for (let widget of this.currentSelectedDashboard.setting) {
				if(data.widget.includes(widget.widgetCd)) {
					widget.syncStatus = this.syncStatusWidget.DOING;
				}
			}

			this.reSyncDatasourcecds = data.dsstructcd;
		});
		//#endregion
	}

	async initData(dasboardSelected: any = null) {
		let dashboardSelected = dasboardSelected != null ? dasboardSelected : this.listDashboard[0];
		if (!Utils.isNullOrEmpty(dashboardSelected)) {
			this.dashboardCd = dashboardSelected?.dashboardCd;
		}

		if (this.dashboardCd.includes('ZF')) {
			this.getZaitakuDetail(this.zaitakuResponse);
		} 
		else {
		await this.getSettingWidgetDetail();
			if (this.dashboards.length > 0) {
				this.currentSelectedDashboard = this.dashboards[0];
				if (this.currentSelectedDashboard?.dashboardCd) {
					await this.getWidget(this.currentSelectedDashboard.dashboardCd);
				}
				if (Utils.isNullOrEmpty(this.currentSelectedDashboard.setting)) {
					this.currentSelectedDashboard.setting = [];
				}
				this.currentSelectedDashboard?.setting?.map((widget: any) => {
					let widgetPosition = JSON.parse(widget?.widgetPosition);
					if (!Utils.isNullOrEmpty(widgetPosition)) {
						widget.x = widgetPosition.x;
						widget.y = widgetPosition.y;
						widget.rows = widgetPosition.rows == undefined ? 7 : widgetPosition.rows;
						widget.cols = widgetPosition.cols == undefined ? 11 : widgetPosition.cols;
					}
					let itemFind = this.listWidget.find(w => w.widgetCd === widget.widgetCd);
					widget.type = itemFind?.chartType;
					let sortParam = !Utils.isNullOrEmpty(itemFind?.sortcoltype)? JSON.parse(itemFind?.sortcoltype || '') : null;
					widget.sortArr = sortParam;
				});
				this.currentSelectedDashboard = cloneDeep(this.currentSelectedDashboard);
				this.isLoading = false;
			}
		}
	}


  async initZataku (){
    let lstZaitaku: any[] = [];		
		await this.checkIfHasZaitaku();
		this.zaitakuResponse = await this.zaitakuService.getAll(false);
		if (this.zaitakuResponse.statuscode === 200 && this.zaitakuResponse.data.length > 0 && this.isHasZaitaiku) {
			this.zaitakuResponse.data?.forEach((dt: any) => {
				let maxDbcd = this.getMaxDashboardCd(this.listDashboard) || 0;
				this.currentZaitakuOfficeNm = dt.officenm;
				let dbcd1 = 'ZF00000000', dbcd2 = 'ZF10000000', dbcd3 = 'ZF20000000';
				let dbcd01 = dbcd1.substring(0, dbcd1.length - (maxDbcd + 1).toString().length) + Number(maxDbcd + 1 );
				let dbcd02 = dbcd2.substring(0, dbcd2.length - (maxDbcd + 2).toString().length) + Number(maxDbcd + 2 );
				let dbcd03 = dbcd3.substring(0, dbcd3.length - (maxDbcd + 3).toString().length) + Number(maxDbcd + 3 );
				// Process for Zaitakufukki
				const [zaitakuOverview, zaitakuDetail, zaitakuByMonth] = [
					{ name: this.currentZaitakuOfficeNm + '_在宅復帰・在宅療養等指標', dashboardCd: dbcd01, jigno: dt.jigno, siteino: dt.siteino, insdate: dt.insdate },
					{ name: this.currentZaitakuOfficeNm + '_在宅復帰・在宅療養等指標（詳細）',  dashboardCd: dbcd02, jigno: dt.jigno, siteino: dt.siteino, insdate: dt.insdate },
					{ name: this.currentZaitakuOfficeNm + '_在宅復帰・在宅療養等指標（各月の実績）',  dashboardCd: dbcd03, jigno: dt.jigno, siteino: dt.siteino, insdate: dt.insdate }
				].map(props => Object.assign(new Dashboard(), props));
				lstZaitaku.unshift(zaitakuByMonth, zaitakuDetail, zaitakuOverview );
				this.listDashboard.unshift(zaitakuOverview, zaitakuDetail, zaitakuByMonth)
			})
			lstZaitaku?.sort((a: any, b: any) => {
				return new Date(b.insdate).getTime() - new Date(a.insdate).getTime();
			});
			lstZaitaku?.forEach(s => {
				let findIndex = this.listDashboard.findIndex(db =>s.dashboardCd == db.dashboardCd) || -1;
				if(findIndex != -1) this.listDashboard.splice(findIndex, 1)
				this.listDashboard.unshift(s);
			})	
		}
    }

	async checkIfHasZaitaku() {
		const services = await this.officeService.getAllOfficeServicesAllowDelFlg();
		if(services && services.length > 0) {
			let isHasZaitakuAndStop = false;
			services.forEach((element: any) => {
			if(isHasZaitakuAndStop)
				return;
			if(element.services.length > 0){
				for(let i = 0; i < element.services.length; i++) {
					let id = element.services[i].id;
					let kindOfService = id.split("_");
					if(parseInt(kindOfService[0]) == 52 && parseInt(kindOfService[1]) == 1) {
						this.isHasZaitaiku = true;
						isHasZaitakuAndStop = true;
					}
				}
			}
		});
		if(!isHasZaitakuAndStop)
			this.isHasZaitaiku = false;
		}
	}

	// load widget content
	loadWidgetContent() {
		if (this.currentSelectedDashboard) {
			setTimeout(() => {
				this.currentSelectedDashboard?.setting?.forEach((widget: any) => {
					let widgetcd = widget.widgetCd || '';
					let widgetElement = document.getElementById(widgetcd);
					if (widgetElement != null) {
						widget.height = (widgetElement && widgetElement.offsetHeight > 80) ? widgetElement.offsetHeight - 80 : 500;
						widget.width = (widgetElement && widgetElement.offsetWidth > 20) ? widgetElement.offsetWidth - 20 : 300;
						widget.isHaveSize = false;
					}
				});
				this.isLoading = false;
			}, 500);
		}
	}
	getMaxDashboardCd(lstDb: any[]) {
		let maxDashboardCd = 0;  
		for (let i = 0; i < lstDb?.length; i++) {
			const dasboardcd = lstDb[i]?.dashboardCd;
			const numberPart = dasboardcd?.substring(3) || 0;
			const dashboardCdNumber = parseInt(numberPart, 10);
			if (dashboardCdNumber > maxDashboardCd) {
				maxDashboardCd = dashboardCdNumber;
			}
		}
		return maxDashboardCd;
	}

	getRowValues(row: HTMLTableRowElement): string[] {
		const cells = Array.from(row.cells);
		return cells.map(cell => cell.textContent || '');
	}
	
	getTableValues(table: HTMLTableElement): string[][] {
		const rows = Array.from(table.rows);
		return rows.map(this.getRowValues);
	}
	
	createWorksheet(values: string[][], sheetName: string): XLSX.WorkSheet {
		const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(values);
		ws['!cols'] = [{ wch: 15 }]; // Đặt độ rộng cột (nếu muốn)
		return ws;
	}

	getSheetNames(dashboardCd: string) {
		let key = dashboardCd.substring(0,3);
		switch(key) {
			case 'ZF0':
				return ['数値', '指標']
			case 'ZF1':
				return ['指標', 'A 在宅復帰率', 'B ベッド回転率', 'C 入所前後訪問指導割合', 'D 退所前後訪問指導割合', 'E 居宅サービスの実施状況', 
				'F リハ専門職員の配置割合', 'G 支援相談員の配置割合', 'H 介護度４又は５の割合', 'I 喀痰吸引の実施割合', 'J 経管栄養の実施割合'];
			case 'ZF2':
				return ['A　在宅復帰率（各月の実績）', 'B　ベッド回転率（各月の実績）', 'C　入所前後訪問指導割合（各月の実績）', 'D　退所前後訪問指導割合（各月の実績）',
						'E　居宅サービスの実施状況（各月の実績）', 'F　リハ専門職員の配置割合（各月の実績）','G　支援相談員の配置割合（各月の実績）', 'H　介護度４又は５の割合（各月の実績）',
						'I　喀痰吸引の実施割合（各月の実績）', 'J　経管栄養の実施割合（各月の実績）']
			default: 
				return [];
		}
	}
	
	exportAllTablesZaitaku(fileName: string) {
		let dashboardCD: string = this.currentSelectedDashboard?.dashboardCd || '';
		let sheetNames: any[] = this.getSheetNames(dashboardCD);
		const tables = document.querySelectorAll('table');
		if (tables?.length === 0) return;
		const wb: XLSX.WorkBook = XLSX.utils.book_new();
		tables.forEach((table, index) => {
			const tableValues = this.getTableValues(table);
			let ws = this.createWorksheet(tableValues, sheetNames[index]);
			ws = this.formatExcelFileZaitaku(ws);
			XLSX.utils.book_append_sheet(wb, ws, sheetNames[index]);
		});
		XLSX.writeFile(wb, fileName);
	}

	formatExcelFileZaitaku(ws: any) {
		const colWidths: any[] = [];
		const range = XLSX.utils.decode_range(ws['!ref']!);
		let border = { style: 'thin', color: { rgb: "969696" } };
		let borderSetting = {
			right: border,
			left: border,
			top: border,
			bottom: border,
		};
		let bgColor = '737F8D';
		let textColor = 'FFFFFF'; 
		const cellHeight = 30; 
	
		const borderHeader: any = {
			font: {
				name: 'Roboto", Arial, sans-serif',
				bold: true,
				color: { rgb: textColor }
			},
			border: borderSetting,
			alignment: {
				vertical: 'center',
				horizontal: 'center',
			},
			fill: { fgColor: { rgb: bgColor } },
			h: cellHeight 
		};

		for (let row = range.s.r; row <= range.e.r; row++) {
			for (let col = range.s.c; col <= range.e.c; col++) {
				const borderRow: any = {
					font: {
						name: 'Roboto", Arial, sans-serif',
					},
					alignment: {
						vertical: 'center',
						horizontal: col ==0? 'left' : 'right',
					},
					border: borderSetting
				};
				const cellAddress = XLSX.utils.encode_cell({ r: row, c: col });
				if(row < 1) {
					ws[cellAddress].s = { ...borderHeader };
					ws[cellAddress].z = 'yyyy/mm';
				}
				else {
					ws[cellAddress].s = { ...borderRow };
				}
				colWidths[col] = col == 0? 40: 10;
			}
		}
	
		ws['!cols'] = ws['!cols'] || [];
		colWidths.forEach((width, colIndex) => {
			ws['!cols'][colIndex] = { width };
		});
	
		ws['!rows'] = ws['!rows'] || [];
		for (let row = range.s.r; row <= range.e.r; row++) {
			ws['!rows'][row] = { hpx: cellHeight }; 
		}
	
		return ws;
	}

	async getListDataExport() {
		let fileName = (this.currentSelectedDashboard.name || 'dashboard') + moment(new Date()).format('YYYYMMDDHHmm') + '.xlsx';
		this.loadingService.isLoading.emit(true);
		if (this.listWidget.length == 0) {
		  const widgetRes = await this.widgetService.getByDashboard(this.dashboardCd);
		  this.listWidget = widgetRes.data || [];
		}
		let strPos, pos;
		// map position to list widget.
		this.listWidget?.map((wg: any) => {
		  if (this.currentSelectedDashboard?.setting) {
			strPos = this.currentSelectedDashboard.setting.find(x => x.widgetCd == wg.widgetCd)?.widgetPosition;
			if (strPos) {
				pos = JSON.parse(strPos);
				wg.position = {x: pos.x, y: pos.y};
			}
		  }
		  return wg;
		});
		// sort list widget by position.
		this.listWidget = this.listWidget?.sort((a, b) => {
			if (a.position.y < b.position.y) return -1;
			if (a.position.y > b.position.y) return 1;
			if (a.position.x < b.position.x) return -1;
			if (a.position.x > b.position.x) return 1;
			return 0;
		});
		
		let widgetCds = this.listWidget.map((x) => x.widgetCd) as string[];
		let widgetNames = this.listWidget.map((x) => x.name) as string[];
		let periodApi = this.widgetService.getPeriodInfor(widgetCds, false);

		let widgetConfigs: any[] = await this.widgetService.getWidgetConfigs(widgetCds);

		let requestBody = {
		  widgetCds: widgetCds,
		  filterCondition: this.filterCondition,
		  selectType: WidgetSelectDataType.TABLEDATA,
		  isTemplate: false,
		} as WidgetResultRequest;
		let widgetResultApi = this.widgetResultService.getByWidgetCds(requestBody);
		let widgetDataTable: any[] = [];
		let [periodRes, widgetResultRes] = await Promise.all([
		  periodApi,
		  widgetResultApi,
		]);
		if (periodRes.statuscode == 200) {
		  this.periods = periodRes.data || [];
		}
		if (widgetResultRes.statuscode == 200) {
			widgetDataTable = widgetResultRes.data || [];
			this.saucerLogService.action(
				{
					content: CONTENT_LOG.EXPORT_EXCEL + this.currentSelectedDashboard.name
				},
				{
					action: SAUCER_LOG_ACTION.HOME.EXPORT_EXCEL
				}
			);

			if(widgetDataTable.length == 0)  { 
				this.exportAllTablesZaitaku(fileName);
				this.loadingService.isLoading.emit(false);
				return;
			}

			widgetDataTable = widgetDataTable?.map((s) => {
				const widgetConfig = widgetConfigs.find(config => config.widgetcd === s.widgetcd);
				return {
				    ...s?.result?.table || [],
				    isKeepOriginTable: readTableConfig(widgetConfig)
				};
			 });
			let dataWorker = {
				typeWorker: "dasboardExportWorker",
				tableData: widgetDataTable,
				sheets: widgetNames,
				widgets: widgetCds,
				setting: this.currentSelectedDashboard.setting
			};

			if (typeof Worker !== 'undefined') {
					this.loadingService.isLoading.emit(true);
					const worker = new Worker(new URL('../../../app/workers/dashboard-export.worker.ts', import.meta.url), { name: 'dashboard_export_worker', type: 'module' });
					worker.postMessage(dataWorker);
					worker.onmessage = ({ data }) => {
					if(data) {
						XLSX.writeFile(data, fileName);
					}
					worker.terminate()
					this.loadingService.isLoading.emit(false);
					};
					
				} else {
					this.loadingService.isLoading.emit(false);
			}
		}
	}

	async getWidget(dashboadCd: string) {
		if(this.listWidget.length == 0 ){
			const widgetRes = await this.widgetService.getByDashboard(dashboadCd);
			this.listWidget = widgetRes.data || [];
		}
		let widgetCds = this.listWidget.map( x => x.widgetCd) as string[];
		let periodApi = this.widgetService.getPeriodInfor(widgetCds, false);
		let requestBody = {
			widgetCds: widgetCds,
			selectType: WidgetSelectDataType.SPECIALCASE,
			isTemplate: false,
			filterCondition: this.filterCondition
		} as WidgetResultRequest

		let widgetResultApi = this.widgetResultService.getByWidgetCds(requestBody);
		let widgetResultData:  any[] = [];
		let [periodRes, widgetResultRes] = await Promise.all([periodApi, widgetResultApi]);
		if (periodRes.statuscode == 200) {
			this.periods = periodRes.data || [];
		}
		if (widgetResultRes.statuscode == 200) {
			widgetResultData = widgetResultRes.data || [];
		}

		this.currentSelectedDashboard?.setting?.map((widget: any) => {
			let findPeriod = this.periods?.find(s => s.widgetcd == widget.widgetCd);
			if (!Utils.isNullOrEmpty(findPeriod)) {
				widget.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
				widget.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
				widget.datasourceCd = findPeriod.dsstructcd;
			}
			const itemFind = this.listWidget?.find(w => w.widgetCd === widget.widgetCd);
			widget.sortcoltype = itemFind?.sortcoltype;
			widget.type = itemFind?.chartType;
			widget.widgetId = itemFind?.id || "";
			let widgetResult = widgetResultData.filter((x:any) => x.widgetcd == widget.widgetCd).pop();
			widget.isLoadWidgetResultComplete = true;
			
			let widgetResultTbl = widgetResult?.result?.table;
			let widgetResultChart = widgetResult?.result?.chart;
			widget.widgetResult = widgetResult?.result;
			if (this.isFilterByOffice) { //filter by Office
				if (widget.type != CHART_TYPE.PIVOT_TABLE) {
					if (!widgetResultChart || (!widgetResultTbl && !widgetResultChart)) {
						widget.syncStatus = this.syncStatusWidget.NO_DATA;
					} else if ((widgetResultTbl?.body?.length == 0) || ( widgetResultChart?.categories?.length == 0) || (widgetResultChart?.columns?.length == 0)) {
						if(widget.type != CHART_TYPE.NUMBER_CHART) {
							widget.syncStatus = this.syncStatusWidget.NO_DATA;
						}
					}
				} else if (!widgetResultTbl && !widgetResultChart) {
					widget.syncStatus = this.syncStatusWidget.NO_DATA;
				}
			}

			let findDataSourceCD = this.listDataSourceCd?.find(s => s.widgetcd == widget.widgetCd);
			if (!Utils.isNullOrEmpty(findDataSourceCD)) {
				widget.datasourceCd = findDataSourceCD.dsstructcd;
				widget.sortArr = !Utils.isNullOrEmpty(itemFind?.sortcoltype)? JSON.parse(itemFind?.sortcoltype || '') : []
			}
		})
	}

	async loadWidgetResult(widget: DashboardSetting) {
		let requestBody = {
			widgetCds: [widget.widgetCd],
			selectType: WidgetSelectDataType.SPECIALCASE,
			isTemplate: false,
			filterCondition: this.filterCondition
		} as WidgetResultRequest
		let widgetResult = await this.widgetResultService.getByWidgetCds(requestBody);
		if(widgetResult.statuscode == 200) {
			if(widgetResult.data[0] && widgetResult.data[0]?.result) {
				widget.widgetResult = widgetResult.data[0]?.result;
				widget.syncStatus = this.syncStatusWidget.LATEST;	
			}
		}
		else {
			widget.syncStatus = this.syncStatusWidget.ERROR
		}
	}

	async loadWidgetPeriod(widget: any) {
		let widgetPeriod = await this.widgetService.getPeriodInfor([widget.widgetCd], false);
		if(widgetPeriod.statuscode == 200 && widgetPeriod.data) {
			let findPeriod =  widgetPeriod.data[0];
			widget.startdate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.startdateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE): (moment(findPeriod.startdate)).format(DateFormat.JP_FULL_SHORT_DATE);
			widget.enddate = findPeriod.dstype == DataSourceType.SEIKUY? (moment(findPeriod.enddateseikuy)).format(DateFormat.JP_FULL_SHORT_DATE) : (moment(findPeriod.enddate)).format(DateFormat.JP_FULL_SHORT_DATE);
		}
	}

	getHeightDashboard() {
		if (!this.gridster) return;
		const minHeight = 'min-height: calc(100vh - 56px)';
		if (this.gridster.mobile) { return `${minHeight}`; }
		const height = this.gridster.curRowHeight * this.gridster.rows + 40;
		return `height: ${height}px; ${minHeight}; `;
	}
	
	openDialogOffice() {
		this.isDspOfficeModal = true;
		this.isResetOffice = true;
	}

	async selectedOffice(office: any[]) {	
		//Log
		if(office) {
			const officeInfo = office.map(office => ({
				officeCd: office.officeCd,
				officeNm: office.officeNm
			  }));
			this.saucerLogService.action(
				{
					content: JSON.stringify(officeInfo)
				}, 
				{ 
					action: SAUCER_LOG_ACTION.HOME.SELECT_BUSINESS_LOCATION
				}
			);
		}
		
		this.loadingService.isLoading.emit(true);
		this.currentSelectedDashboard?.setting?.map((widget: any) =>{
		//   widget.isLoadDataSourceComplete = false;
		  widget.isLoadingWidget= true;
		})
		if (this.isDspOfficeModal) {
			if (this.isResetOffice) {
				this.officeSelected = [];
				this.isResetOffice = false;
			}
			for (let i = 0; i < office?.length; i++) {
				this.officeSelected.push(office[i]?.officeCd);
				this.countOffice++;
			}
			if (this.countOffice == 0 || this.officeSelected.length == 0) {
				this.officeSelected = [];
				//this.officeService.setData(this.officeSelected, LocalStorageKey.OFFICE_SELECTED);
				this.officeService.setOfficeSelected(this.officeSelected);
				this.dashboardFilterService.setDataObservable(this.officeSelected);
				this.isFilterByOffice = false;
			} else if (this.officeSelected.length > 0 && this.countOffice > 0) {
				this.dashboardFilterService.setDataObservable(this.officeSelected);
				//this.officeService.setData(this.officeSelected, LocalStorageKey.OFFICE_SELECTED);
				this.officeService.setOfficeSelected(this.officeSelected);
				this.isFilterByOffice = true;
			} else {
				this.countOffice--;
			}
			
			if (this.officeSelected.length > 0) {
				this.filterCondition.isFullData = false;
			} else {
				this.filterCondition.isFullData = true;
			}
			this.filterCondition.officesSelected = this.officeSelected;
			
			this.loadWidgetContent();
			this.isDspOfficeModal = false;

			if (this.currentSelectedDashboard?.dashboardCd) {
				await this.initData(this.currentdasboard);
				this.loadWidgetContent();
			}
		}
		this.loadingService.isLoading.emit(false);
	}
	closeOfficeModal() {
			this.isDspOfficeModal = false;
	}

	// get list dashboard detail
	async getDashboardDetail() {
		if (!Utils.isNullOrEmpty(this.dashboardCd)) {
			await this.dashboardService.getSettingDetail(this.dashboardCd).then((data: any) => {
				this.dashboards = data || [];
				this.dashboards.map((d: any) => {
					d.isHaveSize = true;
				});
			});
		}
	}

	async getSettingWidgetDetail() {
		if (!Utils.isNullOrEmpty(this.dashboardCd)) {
			await this.dashboardService.getSettingWidgetDetail(this.dashboardCd).then((data: any) => {
				this.dashboards = data || [];
				this.dashboards.map((d: any) => {
					d.isHaveSize = true;
				});
			});
		}
	}

	widgetResize(event: any) {
		let position = JSON.stringify({ "x": event?.itemComponent?.$item?.x, "y": event?.itemComponent?.$item?.y, "rows": event?.itemComponent?.$item?.rows, "cols": event?.itemComponent?.$item?.cols, "width": event?.item?.width, "height": event?.item?.height })
		if (this.currentSelectedDashboard?.setting?.length > 0) {
			this.currentSelectedDashboard?.setting?.map((w: any) => {
				if (w.widgetCd == event.item?.widgetCd) {
					w.widgetPosition = position;
					w.type = event?.item?.type;
					w.width = (event?.itemComponent?.width > 20) ? event?.itemComponent?.width - 20 : 500;
					w.height = (event?.itemComponent?.height > 80) ? event?.itemComponent?.height - 80 : 500;
					w.rows = event?.itemComponent?.$item?.rows;
					w.cols = event?.itemComponent?.$item?.cols;
					w.x = event?.itemComponent?.$item?.x;
					w.y = event?.itemComponent?.$item?.y;
				}
			});
		}
	}

	setOptions() {
		this.options = {
			compactType: gridster.CompactType.CompactUpAndLeft,
			gridType: gridster.GridType.ScrollVertical,
			defaultItemRows: 7,
			defaultItemCols: 14,
			displayGrid: gridster.DisplayGrid.None,
			disableWindowResize: false,
			enableEmptyCellClick: false,
			enableEmptyCellContextMenu: false,
			enableEmptyCellDrop: true,
			enableEmptyCellDrag: false,
			enableOccupiedCellDrop: true,
			emptyCellDragMaxCols: 50,
			emptyCellDragMaxRows: 50,
			margin: 10,
			minCols: 28,
			minRows: 10,
			maxRows: 1000,
			maxCols: 28,
			minItemRows: 4,
			minItemCols: 7,
			maxItemRows: 28,
			draggable: {
				enabled: false,
				ignoreContent: false,
				dragHandleClass: 'gridster-item-content',
			},
			resizable: {
				enabled: false,
			},
			pushItems: true,
			mobileBreakpoint: 640
		};

		if (this.deviceType === DeviceType.MOBILE || this.deviceType === DeviceType.TABLET) {
			this.options.mobileBreakpoint = 9999;
			this.options.keepFixedHeightInMobile = true;
			this.options.fixedRowHeight = 56;
			this.options.outerMarginBottom = 90;
		}
	}

	async onFilterOutData(event: any) {
		this.currentdasboard = event[0];
		this.errorHandleService.setFunctionTitle(FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL);
		this.isLoading = true;
		if(this.dashboardCd == event[0].dashboardCd) return;
		this.listWidget = [];
		this.dashboardCd = event[0].dashboardCd;
		this.loadingService.isLoading.emit(true);
		this.setOptions();
		await this.initData(event[0]);
		this.loadingService.isLoading.emit(false);
		this.loadWidgetContent();
		this.sortData = null;

		//Log
		this.saucerLogService.action({
			content: CONTENT_LOG.DASHBOARD_SWITCHING + JSON.stringify({
				dashboardCd: event[0].dashboardCd,
				name: event[0].name,
			})
		},{
			action: SAUCER_LOG_ACTION.HOME.SWITCH_DASHBOARD
		})
	}

	getWidgetTitle(widgetCd: string = '') {
		let title = '';
		let findItem: any = this.listWidget?.find((w: any) => w.widgetCd === widgetCd);
		if (!Utils.isNullOrEmpty(findItem)) {
			if (!Utils.isNullOrEmpty(findItem.name)) {
				title = findItem.name;
			}
			if (!Utils.isNullOrEmpty(findItem.widgetname)) {
				title = findItem.widgetname;
			}
		}
		else {
			let findItem: any = this.currentSelectedDashboard?.setting?.find((w: any) => w.widgetCd === widgetCd);
			if (!Utils.isNullOrEmpty(findItem)) {
				if (!Utils.isNullOrEmpty(findItem.name)) {
					title = findItem.name;
				}
			}
		}
		return title;
	}

	getWidgetType(widgetCd: string) {
		let findItem = this.listWidget.find(w => w.widgetCd === widgetCd);
		return findItem ? findItem.chartType : 0;
	}

	getWidgetDescription(widgetCd: string = '') {
		let findItem = this.listWidget.find(w => w.widgetCd === widgetCd);
		return findItem && findItem.description ? findItem.description : "";
	}

	getWidgetWidth(widgetCd: string): number {
		let widgetElement = document.getElementById(widgetCd);
		return widgetElement ? widgetElement?.offsetWidth : 300;
	}


	getWidgetHeight(widgetCd: string): number {
		let widgetElement = document.getElementById(widgetCd);
		return widgetElement ? widgetElement?.offsetHeight : 500;
	}

	timeout(ms: any) {
		return new Promise(resolve => setTimeout(resolve, ms))
	}

	openLink(widgetcd: string) {
		this.isOpenDetail = true;
		let dashboardSelected: any = this.currentSelectedDashboard || null;
		let widgetItem = widgetcd ;
		if(dashboardSelected && dashboardSelected?.jigno && dashboardSelected?.siteino) {
			widgetItem = widgetcd + '-' + dashboardSelected?.jigno + '-' + dashboardSelected?.siteino;
		}
		
		const url = this.router.serializeUrl(
			this.router.createUrlTree([LocalStorageHelper.getUrl(ROUTE_PATH.DB_WD_DETAIL), widgetItem])
		);

		//Log
		const content = {
			widgetItem: widgetItem,
			widgetName: !this.dashboardCd.includes('ZF') ? this.getWidgetTitle(widgetcd) : this.getZaitakuItemDetailName(widgetcd),
			url: url
		};
		this.saucerLogService.action({
			content: CONTENT_LOG.VIEW_WIDGET_DETAIL + JSON.stringify(content)
		},{
			action: SAUCER_LOG_ACTION.HOME.VIEW_WIDGET_DETAIL
		});
		//Log
		
		const helper = new JwtHelperService();
		const currentUser = JSON.parse(localStorage.getItem(LocalStorageKey.CURRENT_USER) || '{}');
		let isToekenExpired: any;
		if(currentUser.refreshtoken != null) {
			isToekenExpired = helper.isTokenExpired(currentUser.refreshtoken);
		}
		if(!isToekenExpired || isToekenExpired == false)
			window.open(url, '_blank');
		else {
			this.errorHandleService.customErrorDetails(401, ERROR_TEXT.UNAUTHORIZED_TITLE, ERROR_TEXT.UNAUTHORIZED, "" , FUNCTION_TITLE_TEXT.SCREEN_INIT_FAIL, false);
			this.errorHandleService.navigateTo([ROUTE_PATH.PAGE_ERROR]);
		}
			
	}

	getZaitakuDetail(data: any) {
		let dashboard: any = this.listDashboard.find(s => s.dashboardCd === this.dashboardCd) || this.listDashboard[0];
		this.currentSelectedDashboard = dashboard;
		let dashboardData = data.data?.find((dt: any) => dashboard?.jigno == dt.jigno &&  dashboard.siteino == dt.siteino)
		if(dashboardData) {
			this.currentZaitakuOfficeNm = dashboardData?.officenm;
			let startDate = new Date(dashboardData.sdate);
			let endDate = new Date(dashboardData.edate);
			let dataTable = JSON.parse(dashboardData.datatbl) || [];
			this.hasHospitalNearby = dashboardData.hashospitalnearby ?? false;
			dataTable?.map((item: any) =>  new Date(item.date) < startDate ? item.hide = true : item.hide = false)
			this.dataZaitaku = cloneDeep(dataTable);
			switch (this.dashboardCd?.substring(0,3)) {
				case 'ZF0':
					this.currentSelectedDashboard.setting = ZaitakuItemDetails.slice(0, 1);
					this.currentSelectedDashboard.setting = this.currentSelectedDashboard.setting.map(s => ({ ...s, startdate: startDate, enddate: endDate }))
					break;
				case 'ZF1':
					this.currentSelectedDashboard.setting = ZaitakuItemDetails.slice(1, 12);
					this.currentSelectedDashboard.setting = this.currentSelectedDashboard.setting.map(s => ({ ...s, startdate: startDate, enddate: endDate }))
					break;
				case 'ZF2':
					this.currentSelectedDashboard.setting = ZaitakuItemDetails.slice(12, 22);
					this.currentSelectedDashboard.setting = this.currentSelectedDashboard.setting.map(s => ({ ...s, startdate: startDate, enddate: endDate }))
					break;
			}
		}
	}

	handleSortColData(data: any) {
		if(data) this.sortData = data;
    }
	
	onClickHeaderTable() {
		this.isSortClick = true;
	}

	async onGetWidgetSelect(widget: any) {
        if(!Utils.isNullOrEmpty(this.sortData) && widget && !this.isOpenDetail && this.isSortClick) {
			this.isSortClick = false;
			this.loadingService.isLoading.emit(true);
			let sortcoltype = cloneDeep(this.sortData);
			this.sortData = null;
			await this.widgetService.getWidgetByWidgetcd(widget.widgetCd).then(async (dt: any)=> {
				if(dt.statuscode == 200) {
					let findWidget = dt.data[0];
					if(findWidget) {
						widget.id = findWidget.id;
						findWidget.sortcoltype = JSON.stringify(sortcoltype);
						let widgets: any[] = [];
						widgets.push(findWidget)
						let result = await this.widgetService.updateSortColType(widgets, true);
						if(result.statuscode == 200)  
						{
							this.loadingService.isLoading.emit(false);
						}
					}
				}       
			})
		}
		this.isOpenDetail = false;
	}


	getZaitakuItemDetailName(key: string): string {
		if (["ZD00000001", "ZD00000002"].includes(key)) {
			return this.currentZaitakuOfficeNm + ZaitakuItemDetailName[key];
		} else {
			return ZaitakuItemDetailName[key];
		}
	}
  async showHelpPoup(){
    let userID = this.authenService.getData(LocalStorageKey.USERID) || [];
    let role = '';
    let userRole = (this.authenService.getData(LocalStorageKey.CURRENT_USER_ROLE) || [])
    if(userRole.length >0 ){
      role = userRole[0].roleCd;
    }
    this.loadingService.isLoading.emit(true);
    let request = {
      user_id: userID,
      is_admin: (role == 'RL00000001') || (role == 'RL00000099')
    }
    const helpService = await this.helpListService.getHelpPopup(request);
    if(helpService.statuscode === 200){
      this.lstHelp =  helpService.data;
      this.lstHelp.map((x:any)=>{
        x.displayContent = HelpListHelper.getDisplayContent(x);
      });
      if( this.lstHelp.length > 0){
        this.dataHelpPopup = this.lstHelp[0];
        this.showHelpDialog = true;
			}
		}
	}
  onCloseDialogHelp(item: any) {
    if(item.unread){
      let unread = this.helpListService.getCountUnRead();
      this.helpListService.setCountUnRead(unread - 1);
    }
    let hidePopup =  this.checkHidePopup;
    this.checkHidePopup =false ;
    this.updateHidePopup(item,hidePopup);
    let nextIndex = this.lstHelp.indexOf(item) + 1;
    if (nextIndex < this.lstHelp.length) {
      this.dataHelpPopup = this.lstHelp[nextIndex];
      this.checkHidePopup = false
    } else {
      this.showHelpDialog = false;
    }
  }

  onShowDlg() {
    this.modalData.header = SYSTEM_SETTING.UPDATE_DIALOG_TITLE;
    this.modalData.style = { 'width': '68%' };
    this.modalData.breakpoints = { '960px': '75vw', '640px': '100vw' };
    this.isDspConfirmInviteModal = true;
  }

  onSubmitDlg(data: any) {
    this.isDspConfirmInviteModal = false;
  }
  async updateHidePopup(item: any, hidePopup:boolean) {
    let userID = this.authenService.getData(LocalStorageKey.USERID) || 0;
    const roles = this.authenService.getData(LocalStorageKey.CURRENT_USER_ROLE);
    let roleCd = "";
    if (roles && roles.length > 0) {
      roleCd = roles[0].roleCd;
    }
    let request = {
      user_id: userID,
      is_admin: (roleCd == 'RL00000001') || (roleCd == 'RL00000099'),
      help_id: item.id,
      is_hide_popup: hidePopup
    }
    await this.helpListService.updateHidePopup(request);
  }
  onChangeHidepopup(ischeck:any){
    this.checkHidePopup = ischeck;
  }



	async syncWidgetResult(widget: DashboardSetting) {
		widget.isLoadWidgetResultComplete = false;
		widget.loading = true;  
		if(widget.widgetCd) {

      await this.syncDataSourceDefaultResult(widget.widgetCd, widget.datasourceCd);

			let apiUrl = `WidgetResult/${widget.widgetCd}/${widget.datasourceCd}/${API_APP.WIDGETRESULT.WIDGET_RESULT}`;
			this.widgetResultService.createWidgetResultFromWidgetCdAndDatasourceCd(widget.widgetCd, widget.datasourceCd).then( async(res) => {
				if(res.statuscode == 200) {
					await Promise.all([this.loadWidgetResult(widget), this.loadWidgetPeriod(widget)])
					let logWidget = {
						datasourceCd: widget.datasourceCd,
						widgetCd: widget.widgetCd,
						widgetName: widget.widgetName
					}
					this.saucerLogService.system({
						apiPath: apiUrl,
						body: res,
						statusCode: res.statuscode,
						content: SAUCER_LOG_ACTION.HOME.SYNC_WIDGET_DATA.content + ": " + JSON.stringify(logWidget)
					}, { action: SAUCER_LOG_ACTION.HOME.SYNC_WIDGET_DATA });
				}
				else if(res.statuscode == 500) {
					widget.syncStatus = this.syncStatusWidget.ERROR;
					this.saucerLogService.error({
						apiPath: apiUrl,
						body: res,
						statusCode: res.statuscode
					}, { action: SAUCER_LOG_ACTION.HOME.SYNC_WIDGET_DATA });
				}
			})
			.catch((error) => {
				console.log(error);
				this.saucerLogService.error({
					apiPath: apiUrl,
					body: error,
				}, { action: SAUCER_LOG_ACTION.HOME.SYNC_WIDGET_DATA });
			})
			.finally(() => {
				widget.isLoadWidgetResultComplete = true;
				this.currentSelectedDashboard.setting.map((x: DashboardSetting) =>  {
					if(x.widgetCd == widget.widgetCd ) {
						x.isLoadWidgetResultComplete = true;
						x.syncStatus = widget.syncStatus;
						x.widgetResult = widget.widgetResult;
						x.startdate = widget.startdate;
						x.enddate = widget.enddate;
					}
					return {...x}
				})
				this.currentSelectedDashboard = cloneDeep(this.currentSelectedDashboard);
			})
		
		}
	}
  async syncDataSourceDefaultResult(widgetCd: any, datasourceCd: any) {
    let lambda = await this.defaultValueSettingService.syncDataSourceDefaultResult(widgetCd, datasourceCd);
    if(lambda.statuscode == 200 && lambda?.data?.lambdaresponse?.executionarn) {
      await this.stepFunctionService.checkLambdaStatus(lambda.data.lambdaresponse.executionarn);
    }
  }
}