import { DATASOURCE_SETTING } from "./text-common";

export const DATASOURCE_COMBINATION = [
  {
    //1
    table: "NOTE",
    type: "RECORD",
    name: DATASOURCE_SETTING.RECORD,
    combines: [
      {
        //1.1
        type: "MASTER",
        table: "DAI",
        name: DATASOURCE_SETTING.USER_MASTER,
        date: "2024/01/01 00:00:00"
      },
      {
        //1.2
        type: "MASTER",
        table: "HISNIN",
        name: DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
        date: "2024/01/01 00:00:00"
      },
       //1.3
       {
        table: "JHISJK",
        type: "RECORD",
        name: DATASOURCE_SETTING.RECIPIENT_ID_HISTORY,
        date: "2024/07/24 00:00:00"
      },
      //1.4
      {
        table: "NOTE",
        type: "SCHEDULE",
        name: DATASOURCE_SETTING.SCHEDULE,
        date: "2024/07/24 00:00:00"
      }
    ]
  },
  {
    //2
    table: "NOTE",
    type: "REPORT",
    name: DATASOURCE_SETTING.REPORT,
    combines: [
      {
        //2.1
        type: "MASTER",
        table: "DAI",
        name: DATASOURCE_SETTING.USER_MASTER,
        date: "2024/01/01 00:00:00"
      },
      {
        //2.2
        type: "MASTER",
        table: "HISNIN",
        name: DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
        date: "2024/01/01 00:00:00"
      },
       //2.3
       {
        table: "JHISJK",
        type: "REPORT",
        name: DATASOURCE_SETTING.RECIPIENT_ID_HISTORY,
        date: "2024/07/24 00:00:00"
      },
      //2.4
      {
        table: "NOTE",
        type: "SCHEDULE",
        name: DATASOURCE_SETTING.SCHEDULE,
        date: "2024/07/24 00:00:00"
      }
    ]
  },
  {
    //3
    table: "NOTE",
    type: "SCHEDULE",
    name: DATASOURCE_SETTING.SCHEDULE,
    combines: [
      {
        //3.1
        type: "MASTER",
        table: "DAI x HISNIN",
        name: DATASOURCE_SETTING.USER_MASTER + " x " + DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
        date: "2024/01/01 00:00:00"
      },
      {
        //3.2
        type: "MASTER",
        table: "HISNIN",
        name: DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
        date: "2024/01/01 00:00:00"
      },
      {
        //3.3
        type: "MASTER",
        table: "HISGEN",
        name: DATASOURCE_SETTING.YOTEI,
        date: "2024/01/01 00:00:00"
      },
      {
        //3.4
        type: "MASTER",
        table: "DAI x JHISJK",
        name: DATASOURCE_SETTING.RECIPIENT_ID_HISTORY,
        date: "2024/07/24 00:00:00"
      },
      //3.5
      {
        table: "NOTE",
        type: "RECORD",
        name: DATASOURCE_SETTING.RECORD,
        date: "2024/07/24 00:00:00"
      },
      //3.6
      {
        table: "NOTE",
        type: "REPORT",
        name: DATASOURCE_SETTING.REPORT,
        date: "2024/07/24 00:00:00"
      }
    ]
  },
  {
    //4
    type: "MASTER",
    table: "DAI",
    name: DATASOURCE_SETTING.USER_MASTER,
    combines: [
      {
        //4.1
        table: "NOTE",
        type: "RECORD",
        name: "記録",
        date: "2024/01/01 00:00:00"
      },
       //4.2
      {
        table: "NOTE",
        type: "REPORT",
        name: DATASOURCE_SETTING.REPORT,
        date: "2024/01/01 00:00:00"
      },
       //4.3
      {
        table: "NOTE",
        type: "SCHEDULE",
        name: DATASOURCE_SETTING.SCHEDULE,
        date: "2024/01/01 00:00:00"
      },
       //4.4
      {
        type: "MASTER",
        table: "HISNIN",
        name: DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
        date: "2024/01/01 00:00:00"
      },
       //4.5
      {
        type: "MASTER",
        table: "HISGEN",
        name: DATASOURCE_SETTING.YOTEI,
        date: "2024/01/01 00:00:00"
      },
      //4.6
      {
        table: "HISIO_0",
        type: "MASTER",
        name: DATASOURCE_SETTING.ADMISSION_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //4.7
      {
        table: "HISIO_1",
        type: "MASTER",
        name: DATASOURCE_SETTING.SLEEPOVER_HOSPITALIZATION_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //4.8
      {
        table: "HISIO_2",
        type: "MASTER",
        name: DATASOURCE_SETTING.ROOM_MOVERMENT_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //4.9
      {
        table: "HISIO_3",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_IN_HOME_SERVICE_START_OR_CANCEL,
        date: "2024/01/01 00:00:00"
      },
      // 4.10
      {
        table: "JHISJK",
        type: "MASTER",
        name: DATASOURCE_SETTING.RECIPIENT_ID_HISTORY,
        date: "2024/07/24 00:00:00"
      },
      //4.11
      {
        table: "NOTE",
        type: "MASTER",
        name: DATASOURCE_SETTING.SCHEDULE + " x " + DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION ,
        date: "2024/01/01 00:00:00"
      },
      //4.12
      {
        table: "JHISIO_0",
        type: "MASTER",
        name: DATASOURCE_SETTING.ADMISSION_HISTORY_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      {
        table: "JHISIO_1",
        type: "MASTER",
        name: DATASOURCE_SETTING.SLEEPOVER_HOSPITALIZATION_HISTORY_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      {
        table: "JHISIO_2",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_IN_HOME_SERVICE_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      {
        table: "JHISIO_3",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_SHOFT_TERM_USE_ADDITION_SUPPORT,
        date: "2024/07/24 00:00:00"
      }

    ]
  },
  
  {
    //5
    table: "HISIO",
    type: "MASTER",
    name: DATASOURCE_SETTING.NURSING_CARE_INSURANCE_INFORMATION,
    combines: [
      //5.1
      {
        table: "NOTE",
        type: "RECORD",
        name: "記録",
        date: "2024/01/01 00:00:00"
      },
       //5.2
      {
        table: "NOTE",
        type: "REPORT",
        name: DATASOURCE_SETTING.REPORT,
        date: "2024/01/01 00:00:00"
      },
       //5.3
      {
        table: "NOTE",
        type: "SCHEDULE",
        name: DATASOURCE_SETTING.SCHEDULE,
        date: "2024/01/01 00:00:00"
      },
      //5.4
      {
        table: "HISIO_0",
        type: "MASTER",
        name: DATASOURCE_SETTING.ADMISSION_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //5.5
      {
        table: "HISIO_1",
        type: "MASTER",
        name: DATASOURCE_SETTING.SLEEPOVER_HOSPITALIZATION_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //5.6
      {
        table: "HISIO_2",
        type: "MASTER",
        name: DATASOURCE_SETTING.ROOM_MOVERMENT_HISTORY,
        date: "2024/01/01 00:00:00"
      },
      //5.7
      {
        table: "HISIO_3",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_IN_HOME_SERVICE_START_OR_CANCEL,
        date: "2024/01/01 00:00:00"
      },
      
    ]
  },
  {
    //6
    table: "HISGEN",
    type: "MASTER",
    name: DATASOURCE_SETTING.YOTEI,
    combines: [
      {
        //6.1
        table: "NOTE",
        type: "SCHEDULE",
        name: DATASOURCE_SETTING.SCHEDULE,
        date: "2024/01/01 00:00:00"
      },
    ]
  },
  //7
  {
    type: "MASTER",
    table: "JHISJK",
    name: DATASOURCE_SETTING.RECIPIENT_ID_HISTORY,
    combines: [
      {
        //7.1
        table: "JHISIO_0",
        type: "MASTER",
        name: DATASOURCE_SETTING.ADMISSION_HISTORY_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      {
        //7.2
        table: "JHISIO_1",
        type: "MASTER",
        name: DATASOURCE_SETTING.SLEEPOVER_HOSPITALIZATION_HISTORY_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      { //7.3
        table: "JHISIO_2",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_IN_HOME_SERVICE_SUPPORT,
        date: "2024/07/24 00:00:00"
      },
      { //7.4
        table: "JHISIO_3",
        type: "MASTER",
        name: DATASOURCE_SETTING.HISTORY_OF_SHOFT_TERM_USE_ADDITION_SUPPORT,
        date: "2024/07/24 00:00:00"
      }
    ]
  }
]