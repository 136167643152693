import { Injectable } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WidgetUpdateService {

  private synchronizing = new BehaviorSubject<any>(null);
  currentSynchronizing = this.synchronizing.asObservable().pipe(distinctUntilChanged());

  private synced = new BehaviorSubject<any>(null);
  currentSynced = this.synced.asObservable().pipe(distinctUntilChanged());

  constructor() { }

  changeSynchronizing(data: any) {
    this.synchronizing.next(data);
    this.synced.next(null);
  }
  changeSynced(data: any) {
    this.synchronizing.next(null);
    this.synced.next(data);
  }
}