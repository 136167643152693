import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges, ViewChild, ViewEncapsulation, resolveForwardRef } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { ScrollerOptions } from 'primeng/scroller';
import { LazyTreeNode } from '../../../models/common-model';
import { cloneDeep } from 'lodash';
import { LazyTreeViewService } from './lazy-tree-view.service';

@Component({
  selector: 'pivot-lazy-tree-view',
  templateUrl: './lazy-tree-view.component.html',
  styleUrls: ['./lazy-tree-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [LazyTreeViewService]
})
export class LazyTreeViewComponent implements OnInit, OnChanges {

  @Input() data: any[];
  @Input() dataNode: any;
  @Input() dragScope: string;
  @Input() scrollHeight: number = 0;
  @Input() rowHeight: number = 0;
  @Input() nodeClicked: LazyTreeNode;
  @Input() isZaitaku: boolean = false;
  @Output() nodeClick = new EventEmitter<LazyTreeNode>();
  @Output() leafNodeClick = new EventEmitter<LazyTreeNode[]>();
  @Input() isReLoadData?: boolean = false;
  
  eventLoading: any;
  virtualData: LazyTreeNode[] = [];
  options: ScrollerOptions = {};
  constructor(
    private cdr: ChangeDetectorRef, 
    private lazyTreeViewService: LazyTreeViewService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.virtualData = Array.from({length: this.data.length});
      if (this.isZaitaku) this.virtualData = cloneDeep(this.data);
      //load data of required page
      let loadedItems = [...this.data].slice(0, 1);
      // //populate page of virtual items
      this.virtualData.splice(0, 1,...loadedItems);
      // //trigger change detection
      this.virtualData = [...this.virtualData];
      
      if(this.eventLoading && this.isReLoadData) {
        this.loadData(this.eventLoading);
      }
    }
  }

  ngOnInit(): void {
    this.virtualData = Array.from({length: this.data.length});
  }

  loadData(event: LazyLoadEvent) {
    this.eventLoading = event;
    if (!event.first && event.last === 1 && this.virtualData[0]) {
      return;
    } 

    //load data of required page
    let loadedCars = [...this.data].slice(event.first, event.last);

    // //populate page of virtual cars
    this.virtualData.splice(event.first ?? 0, (event.last ?? 0) - (event.first || 0),...loadedCars);
    
    // //trigger change detection
    this.virtualData = [...this.virtualData];
 
  }


  handleClick(node: LazyTreeNode) {
    this.nodeClick.emit(node);
  }

  onClickLeafNode(nodesSelected: LazyTreeNode[]) {
    this.virtualData.forEach(element => {
      if (element) {
        element.selected = nodesSelected.some((x:any) => x.id == element.id);
        if (element.node) element.node.selected = nodesSelected.some((x:any) => x.id == element.id);
      }
    });
    this.leafNodeClick.emit(nodesSelected);
  }

  unselectAllNodes() {
    //Remove class hover, add class unhover
    const nodeTextContainers = this.el.nativeElement.querySelectorAll('.node-text-container');
    nodeTextContainers.forEach((nodeTextContainer: Element) => {
      const nodeText = nodeTextContainer.querySelector('.node-text.selected');
      if(nodeText) {
        this.renderer.removeClass(nodeText, 'selected');
      }
      const nodeContainerName = nodeTextContainer.querySelector('.node-container-name.hover');
      if(nodeContainerName) {
        this.renderer.removeClass(nodeContainerName, 'hover');
        this.renderer.addClass(nodeContainerName, 'unhover');
      }
    });

    //Set service is null
    this.lazyTreeViewService.resetNodeSelected();

    //Unselect
    this.virtualData.forEach(item => {
      if(item) {
        item.selected = false;
      }
    });
    this.virtualData = [...this.virtualData];
  }
}
